import React, { useState, useEffect, useRef } from "react";
import {
  KeyboardAvoidingView,
  Platform,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ImageBackground,
  Image,
  Alert,
} from "react-native";
import {
  verifyEmailUser,
  ResendVerificationEmailUser,
} from "../../services/authService";

import { createCompany, createInvestor } from "../../services/userService";

const AccountVerificationScreen = ({ navigation }) => {
  const [verificationCode, setVerificationCode] = useState(
    new Array(6).fill("")
  );
  const [resendStatus, setResendStatus] = useState("");
  const inputs = useRef([]);

  useEffect(() => {
    if (localStorage.getItem("jwt_token")) {
      // navigation.navigate('ThankYou');
    }
  }, []);

  const handleChange = (text) => {
    const newCode = text.split("").slice(0, 6);
    while (newCode.length < 6) {
      newCode.push("");
    }
    setVerificationCode(newCode);
    if (text.length >= 6) {
      inputs.current[5].blur();
    }
  };

  const handleKeyPress = (e, index) => {
    if (
      e.nativeEvent.key === "Backspace" &&
      verificationCode[index] === "" &&
      index > 0
    ) {
      inputs.current[index - 1].focus();
    }
  };

  const handleVerify = async () => {
    try {
      const response = await verifyEmailUser(
        localStorage.getItem("email"),
        verificationCode.join("")
      );
      if (response.status === 200) {
        var jwt_token = response.data.jwt_token;
        localStorage.setItem("jwt_token", jwt_token);

        var role = response.data.role;
        localStorage.setItem("role", role);

        switch (role) {
          case "Company": {
            createCompany(jwt_token);
            navigation.navigate("CompanyAfterVerificationScreen");
            break;
          }

          case "Investor": {
            createInvestor(jwt_token);
            navigation.navigate("InvestorLandingScreen");
            break;
          }

          default:
            navigation.navigate("StartScreen");
            break;
        }
      }
    } catch (error) {
      console.log(error);
      Alert.alert("Login Failed", "Invalid email or password");
    }
  };

  const handleResendEmail = async () => {
    try {
      const response = await ResendVerificationEmailUser(
        localStorage.getItem("email")
      );
      if (response.status === 200) {
        setResendStatus("Verification email resent successfully!");
      } else {
        setResendStatus("Failed to resend verification email.");
      }
    } catch (error) {
      setResendStatus("Failed to resend verification email.");
    }
  };

  const logoWidth = 393;
  const logoHeight = 258;
  const companyNameSize = 50; // Renamed for clarity

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingView}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          keyboardVerticalOffset={Platform.OS === "ios" ? 160 : 0}
          enabled
        >
          <View style={styles.inner}>
            <Image
              source={require("../../assets/logo/logo.png")}
              style={[styles.logo, { width: logoWidth, height: logoHeight }]}
            />
            <Text style={[styles.companyName, { fontSize: companyNameSize }]}>
              VELES SECURITIES
            </Text>

            <Text style={styles.headerText}>
              Check your email for verification code
            </Text>

            <View style={styles.codeContainer}>
              {verificationCode.map((digit, index) => (
                <TextInput
                  key={index}
                  ref={(ref) => (inputs.current[index] = ref)}
                  value={digit}
                  onChangeText={(text) =>
                    handleChange(
                      text + verificationCode.slice(index + 1).join("")
                    )
                  }
                  style={styles.codeInput}
                  keyboardType="numeric"
                  maxLength={6}
                  placeholder=""
                  placeholderTextColor="#000000"
                  onKeyPress={(e) => handleKeyPress(e, index)}
                />
              ))}
            </View>

            <TouchableOpacity
              onPress={handleVerify}
              style={styles.verifyButton}
            >
              <Text style={styles.buttonText}>VERIFY</Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={handleResendEmail}
              style={styles.resendLink}
            >
              <Text style={styles.resendText}>
                Didn't get email? Click here to resend
              </Text>
            </TouchableOpacity>

            {resendStatus ? (
              <Text style={styles.resendStatus}>{resendStatus}</Text>
            ) : null}
          </View>
        </KeyboardAvoidingView>
        <View style={styles.fullWidthContainer}>
          <TouchableOpacity
            onPress={() => navigation.navigate("ContactScreen")}
            style={styles.contactContainer}
          >
            <Text style={styles.contactText}>CONTACT US</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  inner: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  logo: {
    marginBottom: 30,
  },
  companyName: {
    marginBottom: 20,
    letterSpacing: 0.5,
    color: "#000000",
    textAlign: "center",
  },
  headerText: {
    fontSize: 20,
    color: "#000000",
    marginBottom: 20,
    textAlign: "center",
  },
  codeContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
  },
  codeInput: {
    width: 50,
    height: 50,
    borderRadius: 5,
    textAlign: "center",
    backgroundColor: "rgba(217, 217, 217, 0.4)",
    fontSize: 25,
    color: "#000000",
    marginHorizontal: 5,
  },
  verifyButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 366,
    height: 50,
    borderRadius: 0,
    textAlign: "center",
    backgroundColor: "rgba(217, 217, 217, 0.4)",
    marginBottom: 20,
  },
  resendLink: {
    marginBottom: 20,
  },
  resendText: {
    fontSize: 15,
    color: "#000000",
    textDecorationLine: "underline",
    textAlign: "center",
  },
  buttonText: {
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
  },
  resendStatus: {
    fontSize: 15,
    color: "green",
    textAlign: "center",
    marginBottom: 20,
  },
  contactContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  fullWidthContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  contactText: {
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
});

export default AccountVerificationScreen;
