import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  Pressable,
  ImageBackground,
  StyleSheet,
  Dimensions,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  Modal,
  Button,
  Picker,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faPaperPlane, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  getChatbotMessages,
  createChatbotConversation,
  sendPromptMessage,
} from "../../services/chatbotService";
import {
  updateDealTerms,
  getDealTermsRecommendation,
  postDeal,
} from "../../services/dealsService";
import { TypingAnimation } from "react-native-typing-animation";

const { height: screenHeight, width: screenWidth } = Dimensions.get("window");

const ChatbotConversationScreen = ({ navigation }) => {
  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("Veles Securities Chatbot");
  const [messages, setMessages] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [dealData, setDealData] = useState({
    StockPrice: "",
    MarketCapitalization: "",
    OutstandingShares: "",
    ATV: "",
    PlacementType: "",
    UseOfProceeds: "",
    IssuanceAmount: "",
  });
  const [isDealGenerated, setIsDealGenerated] = useState(false);
  const [role, setRole] = useState(localStorage.getItem("role"));

  const scrollViewRef = useRef(null);
  const textInputRef = useRef(null);

  const handleSend = async (msg) => {
    console.log(msg);

    textInputRef.current.clear();

    if (msg.trim().length === 0) {
      return;
    }
    let first = 0;
    if (messages.length === 0) {
      await handleFirstPrompt();
      first = 1;
    }
    const selectedChatbotRoomId = localStorage.getItem("chatbotRoomId");
    const jwt_token = localStorage.getItem("jwt_token");
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        message: msg.trim(),
        time: Date.now(),
        isMe: true,
      },
    ]);

    setIsTyping(true);

    try {
      const data = await sendPromptMessage(
        jwt_token,
        selectedChatbotRoomId,
        msg.trim()
      );
      console.log(data);
      if (first) {
        setTitle(data.topic);
      }
      setMessages((prevMessages) => [...prevMessages, ...data.response]);

      if (data.Finished) {
        const results = JSON.parse(data.dealData);
        console.log(results);

        setDealData({
          StockPrice: results["StockPrice"],
          MarketCapitalization: results["MarketCapitalization"],
          OutstandingShares: results["OutstandingShares"],
          ATV: results["ATV"],
          PlacementType: results["PlacementType"],
          UseOfProceeds: results["UseOfProceeds"],
          IssuanceAmount: results["IssuanceAmount"],
        });
        setModalVisible(true);
      }
    } catch (error) {
      console.error("Failed to send message:", error);
    } finally {
      setIsTyping(false); // Hide typing indicator
      setMessage("");
    }
  };

  const renderItem = (item) => {
    return (
      <View
        style={[
          styles.messageBubble,
          item.isMe ? styles.myMessage : styles.otherMessage,
        ]}
      >
        <Text style={styles.messageText}>{item.message}</Text>
      </View>
    );
  };

  const renderTypingIndicator = () => {
    if (isTyping) {
      return (
        <View style={styles.typingIndicator}>
          <Text style={styles.typingText}>
            <TypingAnimation
              dotColor="black"
              dotMargin={3}
              dotAmplitude={3}
              dotSpeed={0.1}
              dotRadius={2.5}
              dotX={12}
              dotY={6}
            />
          </Text>
        </View>
      );
    }
    return null;
  };

  const handleFirstPrompt = async () => {
    const jwt_token = localStorage.getItem("jwt_token");
    try {
      const data = await createChatbotConversation(jwt_token);
      const idChatbotConversation = data.idChatbotConversation;
      localStorage.setItem("chatbotRoomId", idChatbotConversation);
    } catch (error) {
      console.error("Failed to create conversation:", error);
    }
  };

  const fetchMessages = async (
    selectedChatbotRoomId,
    selectedChatbotTopicName
  ) => {
    setTitle(selectedChatbotTopicName);
    const jwt_token = localStorage.getItem("jwt_token");
    try {
      const data = await getChatbotMessages(jwt_token, selectedChatbotRoomId);
      setMessages(data.messages);
    } catch (error) {
      console.error("Failed to fetch messages:", error);
    }
  };

  useEffect(() => {
    const selectedChatbotRoomId = localStorage.getItem("selectedChatbotRoomId");
    const selectedChatbotTopicName = localStorage.getItem(
      "selectedChatbotTopicName"
    );
    if (selectedChatbotRoomId && selectedChatbotTopicName) {
      localStorage.setItem("chatbotRoomId", selectedChatbotRoomId);
      localStorage.removeItem("selectedChatbotRoomId");
      localStorage.removeItem("selectedChatbotTopicName");
      fetchMessages(selectedChatbotRoomId, selectedChatbotTopicName);
    }
  }, []);

  useEffect(() => {
    scrollViewRef.current?.scrollToEnd({ animated: true });
  }, [messages, isTyping]);

  const handlePredefinedMessage = () => {
    const predefinedMessage = "I want to raise money";
    setMessage(predefinedMessage);
    setButtonClicked(true);
    handleSend(predefinedMessage);
  };

  const handleChangeDealTerms = (field, value) => {
    setDealData((prevDealData) => ({
      ...prevDealData,
      [field]: value,
    }));
    const jwt_token = localStorage.getItem("jwt_token");
    const selectedChatbotRoomId = localStorage.getItem("chatbotRoomId");

    updateDealTerms(jwt_token, selectedChatbotRoomId, field, value);
  };

  const handleGenerateDealTerms = async () => {
    console.log("Generating deal with data: ", dealData);
    const jwt_token = localStorage.getItem("jwt_token");
    const selectedChatbotRoomId = localStorage.getItem("chatbotRoomId");

    var data = await getDealTermsRecommendation(
      jwt_token,
      selectedChatbotRoomId
    );
    var new_fields = data.response;
    console.log(new_fields);

    setDealData((prevDealData) => ({
      ...prevDealData,
      NumberOfSecurities: new_fields.NumberOfSecurities || "",
      SecurityType: new_fields.SecurityType || "",
      PricePerShare: new_fields.PricePerShare || "",
    }));

    setIsDealGenerated(true);
  };

  const handlePostDeal = async () => {
    console.log("Posting deal with data: ", dealData);
    const jwt_token = localStorage.getItem("jwt_token");
    const selectedChatbotRoomId = localStorage.getItem("chatbotRoomId");
    postDeal(jwt_token, selectedChatbotRoomId);
    navigation.navigate("StartScreen");
  };

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <ImageBackground
        source={require("../../assets/background/grey.jpg")}
        style={styles.backgroundImage}
      >
        <KeyboardAvoidingView
          style={styles.container}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <View style={styles.header}>
            <Pressable
              onPress={() =>
                navigation.navigate("ChatbotPreviousConversationsScreen")
              }
              style={styles.backButton}
            >
              <FontAwesomeIcon icon={faArrowLeft} color="white" size={30} />
            </Pressable>
            <Text style={styles.headerText}>{title}</Text>
          </View>

          <View style={styles.scrollContainer}>
            <ScrollView
              ref={scrollViewRef}
              contentContainerStyle={styles.scrollViewContent}
              style={{ maxHeight: screenHeight - 185 }}
            >
              {messages.map((message, index) => (
                <View key={index}>{renderItem(message)}</View>
              ))}
              {renderTypingIndicator()}
            </ScrollView>
          </View>

          <View style={styles.messageContainer}>
            <TextInput
              ref={textInputRef}
              style={styles.textInput}
              multiline
              placeholder="Type your prompt..."
              value={message}
              onChangeText={setMessage}
              onSubmitEditing={() => handleSend(message)}
              blurOnSubmit={true}
            />
            <Pressable
              style={styles.sendButton}
              onPress={() => handleSend(message)}
            >
              <FontAwesomeIcon icon={faPaperPlane} color="black" size={25} />
            </Pressable>
          </View>

          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={() => {
              setModalVisible(!modalVisible);
            }}
          >
            <View style={styles.modalView}>
              <Text style={styles.modalText}>Deal Information</Text>
              {Object.keys(dealData).map((key) => (
                <View key={key} style={styles.modalItem}>
                  <Text>{key}</Text>
                  {key === "PlacementType" ||
                  key === "UseOfProceeds" ||
                  key === "SecurityType" ? (
                    <Picker
                      selectedValue={dealData[key]}
                      style={styles.modalPicker}
                      onValueChange={(itemValue) =>
                        handleChangeDealTerms(key, itemValue)
                      }
                    >
                      {key === "PlacementType" &&
                        [
                          "PIPE (Unregistered)",
                          "PIPE (Registered Direct)",
                          "Rule 144A",
                          "PIPE (Registered Direct-CMPO)",
                        ].map((option) => (
                          <Picker.Item
                            label={option}
                            value={option}
                            key={option}
                          />
                        ))}
                      {key === "UseOfProceeds" &&
                        [
                          "Working Capital",
                          "Capital Expenditures",
                          "Research & Development",
                          "Debt Reduction",
                          "Acquisitions Funding",
                          "Product Commercialization",
                          "Market Expansion",
                        ].map((option) => (
                          <Picker.Item
                            label={option}
                            value={option}
                            key={option}
                          />
                        ))}
                      {key === "SecurityType" &&
                        [
                          "Common Stock",
                          "Debt: Convertible",
                          "Preferred Stock",
                          "At-the-Market Offering",
                          "Equity Line",
                          "Prepaid Warrant",
                        ].map((option) => (
                          <Picker.Item
                            label={option}
                            value={option}
                            key={option}
                          />
                        ))}
                    </Picker>
                  ) : (
                    <TextInput
                      style={styles.modalInput}
                      value={dealData[key]}
                      onChangeText={(text) => handleChangeDealTerms(key, text)}
                    />
                  )}
                </View>
              ))}
              {isDealGenerated ? (
                <Button title="POST DEAL" onPress={handlePostDeal} />
              ) : (
                <Button
                  title="GENERATE DEAL TERMS"
                  onPress={handleGenerateDealTerms}
                />
              )}
            </View>
          </Modal>
        </KeyboardAvoidingView>
      </ImageBackground>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginBottom: 5,
  },
  headerText: {
    marginLeft: 15,
    fontSize: 24,
    color: "white",
    textAlign: "center",
  },
  backButton: {
    position: "absolute",
    left: 10,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {
    flex: 1,
    width: "100%",
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: "flex-end",
    paddingBottom: 10,
  },
  messageBubble: {
    maxWidth: "70%",
    padding: 10,
    borderRadius: 20,
    marginVertical: 5,
    position: "relative",
  },
  myMessage: {
    backgroundColor: "#DCF8C6",
    alignSelf: "flex-end",
    marginRight: 15,
  },
  otherMessage: {
    backgroundColor: "#FFFFFF",
    alignSelf: "flex-start",
    marginLeft: 15,
  },
  typingIndicator: {
    alignSelf: "flex-start",
    marginLeft: 15,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 10,
    marginVertical: 5,
    width: 50,
  },
  typingText: {
    fontSize: 16,
    color: "#999",
    marginBottom: 20,
  },
  messageText: {
    fontSize: 16,
  },
  messageMeta: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 5,
  },
  messageTime: {
    fontSize: 12,
    color: "#999",
    marginRight: 10,
  },
  messageRead: {
    fontSize: 12,
    color: "#999",
  },
  messageContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    paddingHorizontal: 15,
    width: "100%",
    marginBottom: 10,
  },
  textInput: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 15,
    fontSize: 18,
    verticalAlign: "top",
    marginHorizontal: 10,
    height: 55,
  },
  sendButton: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    marginBottom: 3,
    marginLeft: 15,
  },
  predefinedButton: {
    backgroundColor: "#007bff",
    borderRadius: 20,
    padding: 10,
    marginHorizontal: 10,
    maxWidth: 250,
    alignSelf: "flex-end",
    marginBottom: 10,
  },
  predefinedButtonText: {
    color: "white",
    fontSize: 16,
    textAlign: "center",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  modalItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 10,
    width: "100%",
  },
  modalInput: {
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    flex: 1,
    marginLeft: 10,
  },
  modalPicker: {
    flex: 1,
    marginLeft: 10,
  },
});

export default ChatbotConversationScreen;
