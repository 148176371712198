import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
  ScrollView,
  Dimensions,
  Modal,
  ActivityIndicator,
  Linking,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faArrowLeft,
  faPenToSquare,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  getConversations,
  check_subscribed,
  subscribe,
} from "../../services/userService";
import { useFocusEffect } from "@react-navigation/native";

const { height: screenHeight } = Dimensions.get("window");

const InboxScreen = ({ navigation }) => {
  const [conversations, setConversations] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchConversation = async () => {
    var jwt_token = localStorage.getItem("jwt_token");
    const data = await getConversations(jwt_token);
    console.log(data);
    setConversations(data);
  };

  const checkSubscriptionStatus = async () => {
    var jwt_token = localStorage.getItem("jwt_token");
    const subscribed = await check_subscribed(jwt_token);
    if (!subscribed) {
      setIsSubscribed(false);
      setModalVisible(true);
    } else {
      setIsSubscribed(true);
      fetchConversation();
    }
  };

  useFocusEffect(
    useCallback(() => {
      checkSubscriptionStatus();
    }, [])
  );

  const handleNavigation = (roomId, name) => {
    localStorage.setItem("selectedRoomId", roomId);
    localStorage.setItem("selectedName", name);
    navigation.navigate("DirectMessageConversationScreen");
  };

  const renderConversations = (item, index) => {
    return (
      <TouchableOpacity
        onPress={() =>
          handleNavigation(item.idDirectConversation, item.RecipientName)
        }
        key={index}
      >
        <View key={item.idDirectConversation} style={styles.card}>
          <View style={styles.leftSection}>
            <Text style={styles.recipientName}>{item.RecipientName}</Text>
            <Text style={styles.lastMessageDate}>{item.LastMessageDate}</Text>
          </View>
          <View style={styles.rightSection}>
            {item.UnreadMessages > 0 && (
              <View style={styles.unreadBadge}>
                <Text style={styles.unreadMessages}>{item.UnreadMessages}</Text>
              </View>
            )}
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const handleSubscribe = async () => {
    setIsLoading(true);
    var jwt_token = localStorage.getItem("jwt_token");

    const currentUrl = window.location.href;
    const regex = /^(https?:\/\/[^\/]+)/;
    const match = currentUrl.match(regex);
    const baseUrl = match[1];

    try {
      var data = await subscribe(jwt_token, baseUrl);
      console.log(data);
      if (data && data.redirect_link) {
        Linking.openURL(data.redirect_link);
      } else {
        alert("Failed to create payment link. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    } finally {
    }
  };

  const closeModalAndNavigate = () => {
    setModalVisible(false);
    navigation.navigate("StartScreen");
  };

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.navigate("StartScreen")}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              color="white"
              size={30}
              style={styles.headerIcon}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate("CreateMessageScreen")}
          >
            <FontAwesomeIcon
              icon={faPenToSquare}
              color="white"
              size={30}
              style={styles.headerIcon}
            />
          </TouchableOpacity>
        </View>

        <View style={styles.scrollContainer}>
          <ScrollView contentContainerStyle={styles.scrollViewContent}>
            {conversations.map((conversation, index) =>
              renderConversations(conversation, index)
            )}
          </ScrollView>
        </View>
      </View>

      {/* Modal for unsubscribed users */}
      <Modal
        transparent={true}
        animationType="slide"
        visible={isModalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={closeModalAndNavigate}
            >
              <FontAwesomeIcon icon={faTimes} color="black" size={16} />
            </TouchableOpacity>
            <Text style={styles.modalText}>
              This is a premium feature. In order to use it, you need to
              subscribe.
            </Text>
            {isLoading ? (
              <ActivityIndicator size="large" color="#0000ff" />
            ) : (
              <TouchableOpacity
                style={styles.modalButton}
                onPress={handleSubscribe}
              >
                <Text style={styles.modalButtonText}>Subscribe</Text>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </Modal>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingHorizontal: 10,
    paddingBottom: 5,
  },
  headerIcon: {
    alignSelf: "center",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {
    height: screenHeight - 80,
  },
  scrollViewContent: {
    paddingVertical: 20,
  },
  card: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    padding: 15,
    marginBottom: 15,
    width: "100%",
    justifyContent: "space-between",
    borderRadius: 10,
  },
  leftSection: {
    flexDirection: "column",
    flex: 1,
  },
  recipientName: {
    fontSize: 16,
    fontWeight: "600",
    color: "#333",
  },
  lastMessageDate: {
    fontSize: 14,
    color: "#666",
    marginTop: 5,
  },
  rightSection: {
    flexDirection: "row",
    alignItems: "center",
  },
  unreadBadge: {
    backgroundColor: "#FF3B30",
    borderRadius: 12,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  unreadMessages: {
    color: "white",
    fontWeight: "600",
    fontSize: 14,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
    position: "relative",
  },
  modalText: {
    marginTop: 5,
    marginBottom: 20,
    fontSize: 18,
    textAlign: "center",
  },
  modalButton: {
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
  modalButtonText: {
    fontFamily: "genos",
    fontSize: 20,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "black",
    textAlign: "center",
  },
  closeButton: {
    position: "absolute",
    top: 10,
    left: 10,
  },
});

export default InboxScreen;
