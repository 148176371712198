import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  Pressable,
  ImageBackground,
  StyleSheet,
  Dimensions,
  TextInput,
  KeyboardAvoidingView,
  Platform,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faPaperclip,
  faPaperPlane,
  faArrowLeft,
  faCheckDouble,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  getMessages,
  sendMessage,
  sendRead,
} from "../../services/messagingService";
import { WEBSOCKET_URL } from "../../services/config";
import { getUserRole } from "../../services/authService";

const { height: screenHeight, width: screenWidth } = Dimensions.get("window");

const DirectMessageConversationScreen = ({ navigation }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [socket, setSocket] = useState(null);
  const [idUser, setIdUser] = useState(-1);
  const [roomName, setRoomName] = useState("");
  const scrollViewRef = useRef(null);

  const [recipientEmail, setRecipientEmail] = useState("");

  const initializeWebSocketConnection = (room_id) => {
    return new Promise((resolve, reject) => {
      const jwt_token = localStorage.getItem("jwt_token");

      const ws = new WebSocket(
        `${WEBSOCKET_URL}?Authorization=${encodeURIComponent(
          jwt_token
        )}&idDirectConversation=${encodeURIComponent(room_id)}`
      );
      setSocket(ws);

      let pingInterval;
      let reconnectTimeout;

      const startHeartbeat = () => {
        clearInterval(pingInterval);
        pingInterval = setInterval(() => {
          if (ws.readyState === WebSocket.OPEN) {
            ws.send(JSON.stringify({ type: "ping" }));
          }
        }, 30000); // Ping every 30 seconds
      };

      const stopHeartbeat = () => {
        clearInterval(pingInterval);
      };

      const reconnect = () => {
        clearTimeout(reconnectTimeout);
        reconnectTimeout = setTimeout(() => {
          initializeWebSocketConnection(room_id)
            .then((newWs) => {
              resolve(newWs);
              console.log("Reconnected successfully");
            })
            .catch((err) => {
              console.error("Reconnection failed:", err);
              reconnect();
            });
        }, 5000); // Reconnect after 5 seconds
      };

      ws.onopen = () => {
        console.log("WebSocket connection opened");
        startHeartbeat();
        resolve(ws);
      };

      ws.onmessage = (event) => {
        console.log("WebSocket message received:", event.data);
        if (event.data) {
          const messageData = JSON.parse(event.data);

          if (messageData.type === "send_message") {
            setMessages((prevMessages) => [...prevMessages, messageData]);
          } else if (messageData.type === "read_message") {
            const messageId = messageData.idMessage;
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.idMessage === messageId
                  ? { ...msg, messageRead: true }
                  : msg
              )
            );
          }
        }
      };

      ws.onerror = (error) => {
        console.error("WebSocket error:", error);
        reject(error);
      };

      ws.onclose = (event) => {
        console.log("WebSocket connection closed:", event);
        setSocket(null);
        stopHeartbeat();
        if (event.wasClean === false) {
          reconnect();
        }
      };

      return () => {
        stopHeartbeat();
        clearTimeout(reconnectTimeout);
        ws.close();
      };
    });
  };

  const handleSend = async () => {
    if (message.trim()) {
      try {
        const jwt_token = localStorage.getItem("jwt_token");
        const room_id = localStorage.getItem("selectedRoomId");
        let ws = socket;

        if (!ws || ws.readyState !== WebSocket.OPEN) {
          ws = await initializeWebSocketConnection(room_id);
        }

        await sendMessage(jwt_token, room_id, message, ws);
        setMessage("");
      } catch (error) {
        console.error("Error sending message:", error);
      }
    } else {
      console.error("Message is empty");
    }
  };

  const handleRead = async (idMessage) => {
    try {
      const jwt_token = localStorage.getItem("jwt_token");
      const room_id = localStorage.getItem("selectedRoomId");
      let ws = socket;

      if (!ws || ws.readyState !== WebSocket.OPEN) {
        ws = await initializeWebSocketConnection(room_id);
      }
      await sendRead(jwt_token, room_id, idMessage, ws);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const renderItem = (item) => {
    const isMe = item.idUserSender == idUser;

    if (!isMe && !item.messageRead) {
      handleRead(item.idMessage);
    }

    return (
      <View
        key={item.idMessage}
        style={[
          styles.messageBubble,
          isMe ? styles.myMessage : styles.otherMessage,
        ]}
      >
        <Text style={styles.messageText}>{item.messageBody}</Text>
        <View style={styles.messageMeta}>
          <Text style={styles.messageTime}>{item.messageSentTime}</Text>
          {
            <FontAwesomeIcon
              icon={faCheckDouble}
              color={item.messageRead ? "blue" : "gray"}
              size={15}
            />
          }
        </View>
      </View>
    );
  };

  const handleInitializeMessageRoom = async (room_id) => {
    const jwt_token = localStorage.getItem("jwt_token");
    const message = localStorage.getItem("message");
    localStorage.removeItem("message");
    try {
      const ws = await socketPromise;
      await sendMessage(jwt_token, room_id, message, ws);
    } catch (error) {
      console.error("Error initializing message room:", error);
    }
  };

  const fetchMessages = async (room_id) => {
    const jwt_token = localStorage.getItem("jwt_token");
    const data = await getMessages(jwt_token, room_id);
    console.log(data);
    setRecipientEmail(data.email);
    setMessages(data.messages);
  };

  const prepareEnv = async () => {
    if (!localStorage.getItem("user_id")) {
      const jwt_token = localStorage.getItem("jwt_token");
      const data = await getUserRole(jwt_token);

      localStorage.setItem("role", data.role);
      localStorage.setItem("user_id", data.user_id);
    }
    setIdUser(localStorage.getItem("user_id"));
  };

  let socketPromise;

  useEffect(() => {
    const name = localStorage.getItem("selectedName");
    setRoomName(name);
    prepareEnv();
    const room_id =
      localStorage.getItem("CreateMessageRoomId") ||
      localStorage.getItem("selectedRoomId");

    socketPromise = initializeWebSocketConnection(room_id);
    var email = localStorage.getItem("recipientEmail");
    if (email) {
      setRecipientEmail(email);
      localStorage.removeItem("recipientEmail");
    }
    socketPromise
      .then(() => {
        if (localStorage.getItem("CreateMessageRoomId")) {
          localStorage.removeItem("CreateMessageRoomId");
          localStorage.setItem("selectedRoomId", room_id);
          handleInitializeMessageRoom(room_id);
        } else {
          fetchMessages(room_id);
        }
      })
      .catch((error) => {
        console.error("Error initializing WebSocket:", error);
      });

    return () => {
      socketPromise.then((ws) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.close();
        }
      });
    };
  }, []);

  useEffect(() => {
    scrollViewRef.current?.scrollToEnd({ animated: true });
  }, [messages]);

  const handleEmail = () => {
    if (recipientEmail) {
      // Construct the mailto link with the recipient's email
      const mailtoLink = `mailto:${recipientEmail}`;

      // Open the default email client with the mailto link
      window.location.href = mailtoLink;
    } else {
      console.log("Recipient email is not available");
    }
  };
  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <ImageBackground
        source={require("../../assets/background/grey.jpg")}
        style={styles.backgroundImage}
      >
        <KeyboardAvoidingView
          style={styles.container}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
        >
          <View style={styles.header}>
            <Pressable
              onPress={() => navigation.navigate("InboxScreen")}
              style={styles.backButton}
            >
              <FontAwesomeIcon icon={faArrowLeft} color="white" size={30} />
            </Pressable>
            <Text style={styles.headerText}>{roomName}</Text>
            <Pressable onPress={() => handleEmail()} style={styles.emailButton}>
              <FontAwesomeIcon icon={faEnvelope} color="white" size={30} />
            </Pressable>
          </View>

          <View style={styles.scrollContainer}>
            <ScrollView
              ref={scrollViewRef}
              contentContainerStyle={styles.scrollViewContent}
              style={{ maxHeight: screenHeight - 185 }}
            >
              {messages.map((message) => renderItem(message))}
            </ScrollView>
          </View>
          <View style={styles.messageContainer}>
            <Pressable style={styles.attachButton}>
              <FontAwesomeIcon icon={faPaperclip} color="black" size={25} />
            </Pressable>
            <TextInput
              style={styles.textInput}
              multiline
              placeholder="Type your message..."
              value={message}
              onChangeText={setMessage}
            />
            <Pressable style={styles.sendButton} onPress={handleSend}>
              <FontAwesomeIcon icon={faPaperPlane} color="black" size={25} />
            </Pressable>
          </View>
        </KeyboardAvoidingView>
      </ImageBackground>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginBottom: 5,
  },
  headerText: {
    fontSize: 24,
    color: "white",
    textAlign: "center",
  },
  backButton: {
    position: "absolute",
    left: 15,
  },
  emailButton: {
    position: "absolute",
    right: 15,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {
    flex: 1,
    width: "100%",
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: "flex-end",
    paddingBottom: 10,
  },
  messageBubble: {
    maxWidth: "70%",
    padding: 10,
    borderRadius: 20,
    marginVertical: 5,
    position: "relative",
  },
  myMessage: {
    backgroundColor: "#DCF8C6",
    alignSelf: "flex-end",
    marginRight: 15,
  },
  otherMessage: {
    backgroundColor: "#FFFFFF",
    alignSelf: "flex-start",
    marginLeft: 15,
  },
  messageText: {
    fontSize: 16,
  },
  messageMeta: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: 5,
  },
  messageTime: {
    fontSize: 12,
    color: "#999",
    marginRight: 10,
  },
  messageRead: {
    fontSize: 12,
    color: "#999",
  },
  messageContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    paddingHorizontal: 15,
    width: "100%",
  },
  textInput: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 15,
    fontSize: 18,
    verticalAlign: "top",
    marginHorizontal: 10,
    height: 55,
  },
  sendButton: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    marginBottom: 3,
    marginLeft: 15,
  },
  attachButton: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
    marginBottom: 3,
    marginRight: 15,
  },
});

export default DirectMessageConversationScreen;
