import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
  StyleSheet,
  ScrollView,
  Dimensions,
  Modal,
  TouchableWithoutFeedback,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faSortAmountDownAlt,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { getInvestors } from "../../services/dealsService";
import Svg, { Path } from "react-native-svg";

const { height: screenHeight } = Dimensions.get("window");

const CompanyLandingPage = ({ navigation }) => {
  const [investors, setInvestors] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchInvestors = async () => {
    try {
      const jwt_token = localStorage.getItem("jwt_token");
      if (jwt_token) {
        const fetchedInvestors = await getInvestors(jwt_token);
        setInvestors(fetchedInvestors.investors);
        localStorage.setItem(
          "investors",
          JSON.stringify(fetchedInvestors.investors)
        );
        localStorage.setItem(
          "investors_expiration",
          Date.now() + 10 * 60 * 1000
        );
      }
    } catch (error) {
      console.error("Error fetching investors:", error);
    }
  };

  useEffect(() => {
    const loadInvestors = () => {
      const storedInvestors = localStorage.getItem("investors");
      const investorsExpiration = localStorage.getItem("investors_expiration");

      if (
        storedInvestors &&
        investorsExpiration &&
        Date.now() < investorsExpiration
      ) {
        setInvestors(JSON.parse(storedInvestors));
      } else {
        fetchInvestors();
      }
    };

    loadInvestors();
    const intervalId = setInterval(() => {
      loadInvestors();
    }, 10 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const extractPathData = (svgString) => {
    const pathRegex = /<path[^>]*d="([^"]*)"/;
    const match = svgString.match(pathRegex);
    return match ? match[1] : "";
  };

  const renderInvestorCard = (investor, index) => {
    const pathData = extractPathData(investor.profilePhoto);
    return (
      <View style={styles.card} key={index}>
        <Svg width="60" height="60" viewBox="0 0 32 32">
          <Path d={pathData} />
        </Svg>
        <Text style={styles.symbol}>{investor.FullName}</Text>
        <Text style={styles.amount}>${investor.totalLifetimeInvestment}</Text>
      </View>
    );
  };

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <TouchableOpacity onPress={() => setModalVisible(true)}>
            <FontAwesomeIcon
              icon={faBars}
              color="white"
              size={30}
              style={styles.headerIcon}
            />
          </TouchableOpacity>
          <FontAwesomeIcon
            icon={faSortAmountDownAlt}
            color="white"
            size={30}
            style={styles.headerIcon}
          />
        </View>
        <View style={styles.scrollContainer}>
          <ScrollView contentContainerStyle={styles.scrollViewContent}>
            {investors.map((investor, index) =>
              renderInvestorCard(investor, index)
            )}
          </ScrollView>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>$100,000</Text>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("ChatbotPreviousConversationsScreen")
            }
          >
            <Image
              source={require("../../assets/chat_icon/VELES.png")}
              style={styles.footerLogo}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate("CompanySettingsScreen")}
          >
            <Text style={styles.footerText}>SETTING</Text>
          </TouchableOpacity>
        </View>

        <Modal
          transparent={true}
          animationType="slide"
          visible={modalVisible}
          onRequestClose={() => setModalVisible(false)}
        >
          <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
            <View style={styles.modalOverlay}>
              <TouchableWithoutFeedback>
                <View style={styles.modalContent}>
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => setModalVisible(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} color="gray" size={24} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.modalButton}
                    onPress={() => {
                      setModalVisible(false);
                      navigation.navigate("CompanyDealsScreen");
                    }}
                  >
                    <Text style={styles.modalButtonText}>Previous Deals</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={styles.modalButton}
                    onPress={() => {
                      setModalVisible(false);
                      navigation.navigate("InboxScreen");
                    }}
                  >
                    <Text style={styles.modalButtonText}>Inbox</Text>
                  </TouchableOpacity>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingHorizontal: 3,
    paddingBottom: 15,
  },
  headerIcon: {
    alignSelf: "center",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {
    height: screenHeight - 210,
  },
  card: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    padding: 15,
    marginVertical: 10,
    width: "100%",
    justifyContent: "space-between",
  },
  logo: {
    width: 40,
    height: 40,
    resizeMode: "contain",
  },
  symbol: {
    fontSize: 24,
    fontFamily: "genos",
  },
  amount: {
    fontSize: 24,
    fontFamily: "genos",
  },
  footer: {
    position: "sticky",
    bottom: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  footerText: {
    color: "white",
    fontSize: 24,
    fontFamily: "genos",
    paddingHorizontal: 8,
    paddingVertical: 12,
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  footerLogo: {
    width: 100,
    height: 100,
    resizeMode: "contain",
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    width: "80%",
    alignItems: "center",
  },
  closeButton: {
    alignSelf: "flex-end",
    marginBottom: 10,
  },
  modalButton: {
    marginVertical: 10,
    padding: 10,
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    width: "100%",
    alignItems: "center",
  },
  modalButtonText: {
    color: "black",
    fontSize: 24,
    fontFamily: "genos",
  },
});

export default CompanyLandingPage;
