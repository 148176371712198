import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
  Dimensions,
  ScrollView,
  TextInput,
  Picker,
  Switch,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  updateCompanyDocumentation,
  getCompanyDocumentation,
} from "../../services/userService";

const { height: screenHeight } = Dimensions.get("window");

const CompanyDocumentationScreen = ({ navigation }) => {
  const [editableField, setEditableField] = useState("");
  const [formData, setFormData] = useState({
    Name: "",
    TickerSymbol: "",
    Industry: "Healthcare",
    Exchange: "NASDAQ",
  });

  const [lastSentFormData, setLastSentFormData] = useState({
    Name: "",
    TickerSymbol: "",
    Industry: "Healthcare",
    Exchange: "NASDAQ",
  });

  useEffect(() => {
    const fetchData = async () => {
      var saved_profile_data = localStorage.getItem("company_documentation");
      if (!saved_profile_data) {
        const jwt_token = localStorage.getItem("jwt_token");
        const data = await getCompanyDocumentation(jwt_token);
        console.log(data);
        setFormData(data);
        setLastSentFormData(data);
        localStorage.setItem("company_documentation", JSON.stringify(data));
      } else {
        setFormData(JSON.parse(saved_profile_data));
        setLastSentFormData(JSON.parse(saved_profile_data));
      }
    };
    fetchData();
  }, []);

  const data = {
    Name: formData.Name,
    TickerSymbol: formData.TickerSymbol,
    Industry: formData.Industry,
    Exchange: formData.Exchange,
  };

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: field === "TickerSymbol" ? value.toUpperCase() : value,
    });
  };

  const handleBlur = () => {
    if (JSON.stringify(lastSentFormData) !== JSON.stringify(formData)) {
      const jwt_token = localStorage.getItem("jwt_token");
      updateCompanyDocumentation(jwt_token, JSON.stringify(formData));
      localStorage.setItem("company_documentation", JSON.stringify(formData));
      setLastSentFormData(formData);
    }
    setEditableField("");
  };

  const renderInput = (field) => {
    switch (field) {
      case "Name":
      case "TickerSymbol":
        return (
          <TextInput
            style={styles.input}
            keyboardType="default"
            onChangeText={(value) => handleInputChange(field, value)}
            value={String(formData[field])}
            onBlur={handleBlur}
            autoFocus
          />
        );
      case "Industry":
      case "Exchange":
        return (
          <Picker
            selectedValue={formData[field]}
            onValueChange={(value) => {
              handleInputChange(field, value);
              setTimeout(handleBlur, 0);
            }}
            style={styles.picker}
          >
            {field === "Industry" &&
              [
                "Healthcare",
                "Technology",
                "Basic Materials",
                "Financial Institutions",
                "Industrial",
                "Energy",
                "Consumer/Retail",
              ].map((item) => (
                <Picker.Item label={item} value={item} key={item} />
              ))}
            {field === "Exchange" &&
              ["NASDAQ", "NYSE", "OTC"].map((item) => (
                <Picker.Item label={item} value={item} key={item} />
              ))}
          </Picker>
        );

      default:
        return null;
    }
  };

  return (
    <TouchableWithoutFeedback
      onPress={() => {
        Keyboard.dismiss();
        handleBlur();
      }}
      accessible={false}
    >
      <ImageBackground
        source={require("../../assets/background/grey.jpg")}
        style={styles.backgroundImage}
      >
        <View style={styles.container}>
          <TouchableOpacity
            style={styles.header}
            onPress={() => navigation.navigate("CompanySettingsScreen")}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              color="white"
              size={30}
              style={styles.headerIcon}
            />
          </TouchableOpacity>

          <ScrollView style={styles.scrollContainer}>
            {Object.keys(data).map((key, index) => (
              <TouchableWithoutFeedback
                key={index}
                onPress={() => {
                  handleBlur();
                  setEditableField(key);
                }}
              >
                <View style={styles.card}>
                  <View style={styles.labelValueContainer}>
                    <Text style={styles.settingsText}>
                      {key.charAt(0).toUpperCase() +
                        key.slice(1).replace("_", " ")}
                      :{" "}
                    </Text>
                    {editableField === key ? (
                      renderInput(key)
                    ) : (
                      <TouchableOpacity
                        onPress={() => {
                          handleBlur();
                          setEditableField(key);
                        }}
                        style={styles.valueContainer}
                      >
                        <Text style={styles.valueText}>{data[key]}</Text>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </TouchableWithoutFeedback>
            ))}
          </ScrollView>
        </View>
        <TouchableOpacity
          style={styles.contactContainer}
          onPress={() => navigation.navigate("ContactScreen")}
        >
          <Text style={styles.contactText}>CONTACT US</Text>
        </TouchableOpacity>
      </ImageBackground>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingHorizontal: 3,
    paddingBottom: 15,
  },
  headerIcon: {
    alignSelf: "center",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {
    height: screenHeight - 220,
  },
  card: {
    alignItems: "center",
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    marginVertical: 10,
    justifyContent: "center",
  },
  labelValueContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  settingsText: {
    fontFamily: "genos",
    fontSize: 25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
  valueContainer: {
    alignItems: "center",
    justifyContent: "center",
  },
  valueText: {
    fontFamily: "genos",
    fontSize: 22,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
  input: {
    borderColor: "gray",
    borderWidth: 1,
    maxWidth: "30%",
    textAlign: "center",
  },
  picker: {
    maxWidth: "70%",
  },
  contactContainer: {
    position: "absolute",
    bottom: 20,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  contactText: {
    fontFamily: "genos",
    fontSize: 25,
    color: "#000000",
    opacity: 0.5,
  },
});

export default CompanyDocumentationScreen;
