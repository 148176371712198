import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
  ScrollView,
  Dimensions,
  Modal,
  TextInput,
  Button,
  Pressable,
  Picker,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faSortAmountDownAlt,
  faBars,
  faArrowLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  getCompanyDeals,
  updateDealTerms,
  getDealTermsRecommendation,
  postDeal,
} from "../../services/dealsService";

const { height: screenHeight } = Dimensions.get("window");

const CompanyDealsScreen = ({ navigation }) => {
  const [deals, setDeals] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [dealData, setDealData] = useState({
    StockPrice: "",
    MarketCapitalization: "",
    OutstandingShares: "",
    ATV: "",
    PlacementType: "",
    UseOfProceeds: "",
    IssuanceAmount: "",
    NumberOfSecurities: "",
    SecurityType: "",
    PricePerShare: "",
  });
  const [isDealGenerated, setIsDealGenerated] = useState(false);
  const [notification, setNotification] = useState("");
  const [showGeneratedFields, setShowGeneratedFields] = useState(false);

  const fetchDeals = async () => {
    const jwt_token = localStorage.getItem("jwt_token");
    const fetchedDeals = await getCompanyDeals(jwt_token);
    setDeals(fetchedDeals.deals);
  };

  useEffect(() => {
    fetchDeals();
  }, []);

  const handleChangeDealTerms = (field, value) => {
    if (!selectedDeal.Active) {
      setDealData((prevDealData) => ({
        ...prevDealData,
        [field]: value,
      }));
      const jwt_token = localStorage.getItem("jwt_token");
      const selectedChatbotRoomId = localStorage.getItem("chatbotRoomId");

      updateDealTerms(jwt_token, selectedChatbotRoomId, field, value);
    }
  };

  const handleGenerateDealTerms = async () => {
    const requiredFields = [
      "StockPrice",
      "MarketCapitalization",
      "OutstandingShares",
      "ATV",
      "PlacementType",
      "UseOfProceeds",
      "IssuanceAmount",
      "NumberOfSecurities",
    ];

    const emptyFields = requiredFields.filter((field) => !dealData[field]);

    if (emptyFields.length > 0) {
      setNotification(
        `Please fill in all required fields: ${emptyFields.join(", ")}`
      );
      return;
    }

    console.log("Generating deal with data: ", dealData);
    const jwt_token = localStorage.getItem("jwt_token");
    const selectedChatbotRoomId = localStorage.getItem("chatbotRoomId");

    const data = await getDealTermsRecommendation(
      jwt_token,
      selectedChatbotRoomId
    );
    const new_fields = data.response;

    setDealData((prevDealData) => ({
      ...prevDealData,
      SecurityType: new_fields.SecurityType || "",
      PricePerShare: new_fields.PricePerShare || "",
    }));

    setIsDealGenerated(true);
    setShowGeneratedFields(true); // Show generated fields
    setNotification(""); // Clear notification if deal terms are successfully generated
  };

  const handlePostDeal = async () => {
    console.log("Posting deal with data: ", dealData);
    const jwt_token = localStorage.getItem("jwt_token");
    const selectedChatbotRoomId = localStorage.getItem("chatbotRoomId");
    await postDeal(jwt_token, selectedChatbotRoomId);
    navigation.navigate("StartScreen");
  };

  const openDealModal = (deal) => {
    setDealData(deal);
    setSelectedDeal(deal);
    setModalVisible(true);
  };

  const closeDealModal = () => {
    setModalVisible(false);
    setSelectedDeal(null);
    setIsDealGenerated(false);
    setNotification(""); // Clear notification when closing the modal
    setShowGeneratedFields(false); // Hide generated fields when closing the modal
  };

  const renderDealCard = (deal, index) => {
    return (
      <TouchableOpacity
        key={index}
        style={styles.card}
        onPress={() => openDealModal(deal)}
      >
        <Text style={styles.symbol}>{deal.CompanyName}</Text>
        <Text style={styles.amount}>${deal.IssuanceAmount}</Text>
        <Text style={styles.symbol}>
          {deal.Active ? "POSTED" : "IN PROGRESS"}
        </Text>
      </TouchableOpacity>
    );
  };

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <Pressable
            onPress={() => navigation.navigate("StartScreen")}
            style={styles.backButton}
          >
            <FontAwesomeIcon icon={faArrowLeft} color="white" size={30} />
          </Pressable>
          <FontAwesomeIcon
            icon={faSortAmountDownAlt}
            color="white"
            size={30}
            style={styles.headerIcon}
          />
        </View>
        <View style={styles.scrollContainer}>
          <ScrollView contentContainerStyle={styles.scrollViewContent}>
            {deals.map((deal, index) => renderDealCard(deal, index))}
          </ScrollView>
        </View>

        {selectedDeal && (
          <Modal
            animationType="slide"
            transparent={true}
            visible={modalVisible}
            onRequestClose={closeDealModal}
          >
            <View style={styles.modalView}>
              <Text style={styles.modalText}>Deal Information</Text>
              {notification ? (
                <Text style={styles.notification}>{notification}</Text>
              ) : null}
              {[
                "StockPrice",
                "MarketCapitalization",
                "OutstandingShares",
                "ATV",
                "PlacementType",
                "UseOfProceeds",
                "IssuanceAmount",
                "NumberOfSecurities",
              ].map((key) => (
                <View key={key} style={styles.modalItem}>
                  <Text>{key}</Text>
                  {key === "PlacementType" || key === "UseOfProceeds" ? (
                    <Picker
                      selectedValue={dealData[key] || ""}
                      style={styles.modalPicker}
                      onValueChange={(itemValue) =>
                        handleChangeDealTerms(key, itemValue)
                      }
                      enabled={!selectedDeal.Active}
                    >
                      {key === "PlacementType" &&
                        [
                          "PIPE (Unregistered)",
                          "PIPE (Registered Direct)",
                          "Rule 144A",
                          "PIPE (Registered Direct-CMPO)",
                        ].map((option) => (
                          <Picker.Item
                            label={option}
                            value={option}
                            key={option}
                          />
                        ))}
                      {key === "UseOfProceeds" &&
                        [
                          "Working Capital",
                          "Capital Expenditures",
                          "Research & Development",
                          "Debt Reduction",
                          "Acquisitions Funding",
                          "Product Commercialization",
                          "Market Expansion",
                        ].map((option) => (
                          <Picker.Item
                            label={option}
                            value={option}
                            key={option}
                          />
                        ))}
                    </Picker>
                  ) : (
                    <TextInput
                      style={styles.modalInput}
                      value={dealData[key] ? dealData[key].toString() : ""}
                      onChangeText={(text) => handleChangeDealTerms(key, text)}
                      editable={!selectedDeal.Active}
                    />
                  )}
                </View>
              ))}
              {dealData.SecurityType && dealData.PricePerShare && (
                <>
                  <View style={styles.modalItem}>
                    <Text>SecurityType</Text>
                    <Picker
                      selectedValue={dealData.SecurityType || ""}
                      style={styles.modalPicker}
                      onValueChange={(itemValue) =>
                        handleChangeDealTerms("SecurityType", itemValue)
                      }
                      enabled={!selectedDeal.Active}
                    >
                      {[
                        "Common Stock",
                        "Debt: Convertible",
                        "Preferred Stock",
                        "At-the-Market Offering",
                        "Equity Line",
                        "Prepaid Warrant",
                      ].map((option) => (
                        <Picker.Item
                          label={option}
                          value={option}
                          key={option}
                        />
                      ))}
                    </Picker>
                  </View>
                  <View style={styles.modalItem}>
                    <Text>PricePerShare</Text>
                    <TextInput
                      style={styles.modalInput}
                      value={dealData.PricePerShare || ""}
                      onChangeText={(text) =>
                        handleChangeDealTerms("PricePerShare", text)
                      }
                      editable={!selectedDeal.Active}
                    />
                  </View>
                </>
              )}
              {!selectedDeal.Active &&
                (dealData.SecurityType && dealData.PricePerShare ? (
                  <Button title="POST DEAL" onPress={handlePostDeal} />
                ) : (
                  <Button
                    title="GENERATE DEAL TERMS"
                    onPress={handleGenerateDealTerms}
                  />
                ))}
              <TouchableOpacity
                style={styles.closeButton}
                onPress={closeDealModal}
              >
                <FontAwesomeIcon icon={faTimes} color="gray" size={24} />
              </TouchableOpacity>
            </View>
          </Modal>
        )}
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingHorizontal: 3,
    paddingBottom: 15,
  },
  headerIcon: {
    alignSelf: "center",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {
    height: screenHeight - 210,
  },
  scrollViewContent: {
    flexGrow: 1,
    justifyContent: "flex-start",
  },
  card: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    padding: 15,
    marginVertical: 10,
    width: "100%",
    justifyContent: "space-between",
  },
  logo: {
    width: 40,
    height: 40,
    resizeMode: "contain",
  },
  symbol: {
    fontSize: 24,
    fontFamily: "genos",
  },
  amount: {
    fontSize: 24,
    fontFamily: "genos",
  },
  footer: {
    position: "sticky",
    bottom: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  footerText: {
    color: "white",
    fontSize: 24,
    fontFamily: "genos",
    paddingHorizontal: 8,
    paddingVertical: 12,
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  footerLogo: {
    width: 100,
    height: 100,
    resizeMode: "contain",
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  notification: {
    color: "red",
    marginBottom: 10,
  },
  modalItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 10,
    width: "100%",
  },
  modalInput: {
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    flex: 1,
    marginLeft: 10,
  },
  modalPicker: {
    flex: 1,
    marginLeft: 10,
  },
  closeButton: {
    alignSelf: "center",
    marginTop: 20,
  },
});

export default CompanyDealsScreen;
