import React, { useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
  StyleSheet,
  Linking,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import useFonts from "../hooks/useFonts";
import { validate_subscription } from "../services/userService";

const PaymentSuccessfulScreen = ({ navigation }) => {
  const fontsLoaded = useFonts();

  useEffect(() => {
    const logSessionId = async () => {
      const url = await Linking.getInitialURL();
      if (url) {
        const sessionId = new URL(url).searchParams.get("session_id");
        console.log("Session ID:", sessionId);
        if (sessionId) {
          var jwt_token = localStorage.getItem("jwt_token");
          var data = await validate_subscription(jwt_token, sessionId);
          console.log(data);
          if (!data || !data.message) {
            navigation.navigate("PaymentUnsuccessfulScreen");
          }
        } else {
          navigation.navigate("PaymentUnsuccessfulScreen");
        }
      }
    };

    logSessionId();
  }, []);

  const logoWidth = 393;
  const logoHeight = 258;
  const companyName = 50;

  return (
    <ImageBackground
      source={require("../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <TouchableOpacity
        style={styles.header}
        onPress={() => navigation.navigate("StartScreen")}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color="white"
          size={30}
          style={styles.headerIcon}
        />
      </TouchableOpacity>
      <View style={styles.container}>
        <Image
          source={require("../assets/logo/logo.png")}
          style={[styles.logo, { width: logoWidth, height: logoHeight }]}
        />

        <Text style={[styles.companyName, { fontSize: companyName }]}>
          VELES SECURITIES
        </Text>
        <Text style={styles.thankYouText}>
          Thank you for subscribing! Enjoy our tools!
        </Text>
        <TouchableOpacity
          style={[styles.button, styles.loginButton]}
          onPress={() => navigation.navigate("StartScreen")}
        >
          <Text style={styles.buttonText}>CONTINUE</Text>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 25,
    paddingLeft: 20,
  },
  headerIcon: {
    alignSelf: "center",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    marginTop: -15,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  logo: {
    marginBottom: 30,
  },
  companyName: {
    marginBottom: 20,
    fontFamily: "zen",
    letterSpacing: 0.5,
    color: "#000000",
    textAlign: "center",
  },
  thankYouText: {
    fontFamily: "genos", // or another font if "genos" isn't suitable
    fontSize: 28, // adjust the size as needed
    color: "#000000", // black color
    opacity: 0.8, // slight transparency
    textAlign: "center",
    marginBottom: 20,
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    width: 366,
    height: 35,
    borderRadius: 0,
    textAlign: "center",
    marginBottom: 20,
  },
  loginButton: {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  buttonText: {
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
  contactContainer: {
    position: "absolute",
    bottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  contactText: {
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
});

export default PaymentSuccessfulScreen;
