import React from "react";
import useFonts from "../hooks/useFonts"; // Adjust the path as necessary
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
  StyleSheet,
  Linking,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const ContactScreen = ({ navigation }) => {
  const fontsLoaded = useFonts();

  const logoWidth = 393;
  const logoHeight = 258;
  const companyName = 50;

  // Function to handle phone call
  const handleCall = () => {
    const phoneNumber = "1234567890"; // Replace with your actual phone number
    Linking.openURL(`tel:${phoneNumber}`);
  };

  // Function to handle email
  const handleEmail = () => {
    const email = "email@example.com"; // Replace with your actual email address
    Linking.openURL(`mailto:${email}`);
  };

  return (
    <ImageBackground
      source={require("../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <TouchableOpacity
        style={styles.header}
        onPress={() => navigation.navigate("StartScreen")}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color="white"
          size={30}
          style={styles.headerIcon}
        />
      </TouchableOpacity>
      <View style={styles.container}>
        <Image
          source={require("../assets/logo/logo.png")}
          style={[styles.logo, { width: logoWidth, height: logoHeight }]}
        />

        <Text style={[styles.companyName, { fontSize: companyName }]}>
          VELES SECURITIES
        </Text>

        <TouchableOpacity
          style={[styles.button, styles.loginButton]}
          onPress={handleEmail}
        >
          <Text style={styles.buttonText}>EMAIL US</Text>
        </TouchableOpacity>

        <TouchableOpacity
          style={[styles.button, styles.accountButton]}
          onPress={handleCall}
        >
          <Text style={styles.buttonText}>CALL US</Text>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 25,
    paddingLeft: 20,
  },
  headerIcon: {
    alignSelf: "center",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    marginTop: -15,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  logo: {
    marginBottom: 30,
  },
  companyName: {
    marginBottom: 20,
    fontFamily: "zen",
    letterSpacing: 0.5,
    color: "#000000",
    textAlign: "center",
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    width: 366,
    height: 35,
    borderRadius: 0,
    textAlign: "center",
    marginBottom: 20,
  },
  loginButton: {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  accountButton: {
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  buttonText: {
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
  contactContainer: {
    position: "absolute",
    bottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  contactText: {
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
});

export default ContactScreen;
