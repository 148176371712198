import * as React from "react";
import { NavigationContainer } from "@react-navigation/native";
import "react-native-gesture-handler";
import { createStackNavigator } from "@react-navigation/stack";
import * as Linking from "expo-linking";

import withAuth from "./utils/withAuth";

// Auth Screens
import LoginScreen from "./views/Auth/LoginScreen";
import SignUpScreen from "./views/Auth/SignUpScreen";
import StartScreen from "./views/Auth/StartScreen";
import AccountVerificationScreen from "./views/Auth/AccountVerificationScreen";

// Investor Screens
import InvestorLandingScreen from "./views/Investor/InvestorLandingScreen";
import InvestorProfileScreen from "./views/Investor/InvestorProfileScreen";
import InvestorSettingsScreen from "./views/Investor/InvestorSettingsScreen";
import InvestorPersonalInformationScreen from "./views/Investor/InvestorPersonalInformationScreen";

// Company Screens
import CompanyLandingScreen from "./views/Company/CompanyLandingScreen";
import CompanyAfterVerificationScreen from "./views/Company/CompanyAfterVerificationScreen";
import CompanySettingsScreen from "./views/Company/CompanySettingsScreen";
import CompanyDocumentationScreen from "./views/Company/CompanyDocumentationScreen";
import CompanyDealsScreen from "./views/Company/CompanyDealsScreen";

// Miscellaneous Screens
import FAQScreen from "./views/FAQScreen";
import ContactScreen from "./views/ContactScreen";
import PaymentSuccessfulScreen from "./views/PaymentSuccessfulScreen";
import PaymentUnsuccessfulScreen from "./views/PaymentUnsuccessfulScreen";

// Direct Messaging Screens
import InboxScreen from "./views/DirectMessaging/InboxScreen";
import CreateMessageScreen from "./views/DirectMessaging/CreateMessageScreen";
import DirectMessageConversationScreen from "./views/DirectMessaging/DirectMessageConversationScreen";

// Chatbot Screens
import ChatbotConversationScreen from "./views/Chatbot/ChatbotConversationScreen";
import ChatbotPreviousConversationsScreen from "./views/Chatbot/ChatbotPreviousConversationsScreen";

// Wrapping screens with authentication and role-based access control
// Investor Screens
const WrappedInvestorLandingScreen = withAuth(InvestorLandingScreen, true, [
  "Investor",
]);
const WrappedInvestorProfileScreen = withAuth(InvestorProfileScreen, true, [
  "Investor",
]);
const WrappedInvestorSettingsScreen = withAuth(InvestorSettingsScreen, true, [
  "Investor",
]);
const WrappedInvestorPersonalInformationScreen = withAuth(
  InvestorPersonalInformationScreen,
  true,
  ["Investor"]
);

// Company Screens
const WrappedCompanyLandingScreen = withAuth(CompanyLandingScreen, true, [
  "Company",
]);
const WrappedCompanyAfterVerificationScreen = withAuth(
  CompanyAfterVerificationScreen,
  true,
  ["Company"]
);
const WrappedCompanySettingsScreen = withAuth(CompanySettingsScreen, true, [
  "Company",
]);
const WrappedCompanyDocumentationScreen = withAuth(
  CompanyDocumentationScreen,
  true,
  ["Company"]
);
const WrappedCompanyDealsScreen = withAuth(CompanyDealsScreen, true, [
  "Company",
]);

const WrappedPaymentSuccessfulScreen = withAuth(PaymentSuccessfulScreen, true, [
  "Company",
  "Investor",
]);
const WrappedPaymentUnsuccessfulScreen = withAuth(
  PaymentUnsuccessfulScreen,
  true,
  ["Company", "Investor"]
);

// Other Screens
const WrappedContactScreen = withAuth(ContactScreen, false);
const WrappedFAQScreen = withAuth(FAQScreen, true);
const WrappedDirectMessageConversationScreen = withAuth(
  DirectMessageConversationScreen,
  true
);
const WrappedInboxScreen = withAuth(InboxScreen, true);
const WrappedCreateMessageScreen = withAuth(CreateMessageScreen, true);
const WrappedChatbotConversationScreen = withAuth(
  ChatbotConversationScreen,
  true
);
const WrappedChatbotPreviousConversationsScreen = withAuth(
  ChatbotPreviousConversationsScreen,
  true
);

// Auth Components
const StartScreenComponent = withAuth(StartScreen, false);
const LoginScreenComponent = LoginScreen;
const SignUpScreenComponent = SignUpScreen;
const AccountVerificationComponent = AccountVerificationScreen;

const Stack = createStackNavigator();

const prefix = Linking.createURL("/");

const linking = {
  prefixes: [prefix, "velessecurities.com", "myapp://"],
  config: {
    screens: {
      StartScreen: "",
      LoginScreen: "login",
      ContactScreen: "contact",
      SignUpScreen: "register",
      AccountVerificationScreen: "verify",
      InvestorLandingScreen: "investor",
      InvestorSettingsScreen: "investor/settings",
      InvestorProfileScreen: "investor/profile",
      InvestorPersonalInformationScreen: "investor/personal-information",
      CompanyLandingScreen: "company",
      CompanyAfterVerificationScreen: "company/after-verification",
      CompanySettingsScreen: "company/settings",
      CompanyDocumentationScreen: "company/documentation",
      CompanyDealsScreen: "company/deals",
      FAQScreen: "faq",
      InboxScreen: "inbox",
      CreateMessageScreen: "create",
      DirectMessageConversationScreen: "conversation",
      ChatbotConversationScreen: "chatbot/conversation",
      ChatbotPreviousConversationsScreen: "chatbot",
      PaymentSuccessfulScreen: "payment-successful",
      PaymentUnsuccessfulScreen: "payment-unsuccessful",
    },
  },
};

export default function App() {
  return (
    <NavigationContainer linking={linking}>
      <Stack.Navigator initialRouteName="StartScreen">
        <Stack.Screen
          name="StartScreen"
          component={StartScreenComponent}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreenComponent}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ContactScreen"
          component={WrappedContactScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="SignUpScreen"
          component={SignUpScreenComponent}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="AccountVerificationScreen"
          component={AccountVerificationComponent}
          options={{ headerShown: false }}
        />
        {/* Investor Screens */}
        <Stack.Screen
          name="InvestorLandingScreen"
          component={WrappedInvestorLandingScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="InvestorSettingsScreen"
          component={WrappedInvestorSettingsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="InvestorProfileScreen"
          component={WrappedInvestorProfileScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="InvestorPersonalInformationScreen"
          component={WrappedInvestorPersonalInformationScreen}
          options={{ headerShown: false }}
        />
        {/* Company Screens */}
        <Stack.Screen
          name="CompanyLandingScreen"
          component={WrappedCompanyLandingScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CompanyAfterVerificationScreen"
          component={WrappedCompanyAfterVerificationScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CompanySettingsScreen"
          component={WrappedCompanySettingsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CompanyDocumentationScreen"
          component={WrappedCompanyDocumentationScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CompanyDealsScreen"
          component={WrappedCompanyDealsScreen}
          options={{ headerShown: false }}
        />
        {/* Other Screens */}
        <Stack.Screen
          name="FAQScreen"
          component={WrappedFAQScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="InboxScreen"
          component={WrappedInboxScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="CreateMessageScreen"
          component={WrappedCreateMessageScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="DirectMessageConversationScreen"
          component={WrappedDirectMessageConversationScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ChatbotConversationScreen"
          component={WrappedChatbotConversationScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="ChatbotPreviousConversationsScreen"
          component={WrappedChatbotPreviousConversationsScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="PaymentSuccessfulScreen"
          component={WrappedPaymentSuccessfulScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="PaymentUnsuccessfulScreen"
          component={WrappedPaymentUnsuccessfulScreen}
          options={{ headerShown: false }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
