import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
  Dimensions,
  TextInput,
  Picker,
  TouchableWithoutFeedback,
  Keyboard,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faPaperclip,
  faPaperPlane,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { getRecipients } from "../../services/userService";
import { createMessageRoom } from "../../services/messagingService";

const { height: screenHeight, width: screenWidth } = Dimensions.get("window");

const CreateMessageScreen = ({ navigation }) => {
  const [recipients, setRecipients] = useState([]);
  const [selectedRecipientId, setSelectedRecipientId] = useState("");
  const [selectedRecipientName, setSelectedRecipientName] = useState("");
  const [message, setMessage] = useState("");
  const [inputHeight, setInputHeight] = useState(40);

  const handleSend = async () => {
    console.log("Message sent to:", selectedRecipientId);
    console.log("Message content:", message);
    const jwt_token = localStorage.getItem("jwt_token");
    console.log(selectedRecipientId);
    const data = await createMessageRoom(jwt_token, selectedRecipientId);
    const room_id = data.direct_conversation_id;
    localStorage.setItem("recipientEmail", data.email);
    localStorage.setItem("selectedName", selectedRecipientName);
    localStorage.setItem("message", message);
    localStorage.setItem("CreateMessageRoomId", room_id);
    navigation.navigate("DirectMessageConversationScreen");
  };

  const handleAttach = async () => {
    // todo
  };

  const fetchRecipients = async () => {
    try {
      const jwt_token = localStorage.getItem("jwt_token");
      const data = await getRecipients(jwt_token);
      const filteredRecipients = Array.isArray(data)
        ? data.filter((item) => item.Name)
        : [];
      setRecipients(filteredRecipients);
      if (filteredRecipients.length > 0) {
        setSelectedRecipientId(filteredRecipients[0].idUser);
        setSelectedRecipientName(filteredRecipients[0].Name);
      }
    } catch (error) {
      console.error("Failed to fetch recipients:", error);
      setRecipients([]);
    }
  };

  const handleValueChange = (itemValue, itemIndex) => {
    const recipientName = recipients[itemIndex].Name;
    const recipientId = recipients[itemIndex].idUser;
    console.log(recipientName + " " + recipientId + " === " + itemValue);
    setSelectedRecipientId(recipientId);
    setSelectedRecipientName(recipientName);
  };

  useEffect(() => {
    fetchRecipients();
  }, []);

  return (
    <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>
      <ImageBackground
        source={require("../../assets/background/grey.jpg")}
        style={styles.backgroundImage}
      >
        <View style={styles.container}>
          <View style={styles.header}>
            <TouchableOpacity
              onPress={() => navigation.navigate("InboxScreen")}
              style={styles.backButton}
            >
              <FontAwesomeIcon icon={faArrowLeft} color="white" size={30} />
            </TouchableOpacity>
            <Text style={styles.headerText}>New Message</Text>
          </View>
          <View style={styles.recipientContainer}>
            <Text style={styles.recipientLabel}>To:</Text>
            <Picker
              selectedValue={selectedRecipientId}
              onValueChange={handleValueChange}
              style={styles.picker}
            >
              {recipients.map((recipient) => (
                <Picker.Item
                  label={recipient.Name}
                  value={recipient.idUser}
                  key={recipient.idUser}
                />
              ))}
            </Picker>
          </View>
          <View style={styles.separator} />
          <View style={styles.messageContainer}>
            <TouchableOpacity
              style={styles.attachButton}
              onPress={handleAttach}
            >
              <FontAwesomeIcon icon={faPaperclip} color="black" size={25} />
            </TouchableOpacity>
            <TextInput
              style={[
                styles.textInput,
                {
                  height: Math.min(
                    Math.max(60, inputHeight),
                    screenHeight - 220
                  ),
                },
              ]}
              multiline
              placeholder="Type your message..."
              value={message}
              onChangeText={setMessage}
              onContentSizeChange={(e) =>
                setInputHeight(e.nativeEvent.contentSize.height)
              }
            />
            <TouchableOpacity style={styles.sendButton} onPress={handleSend}>
              <FontAwesomeIcon icon={faPaperPlane} color="black" size={25} />
            </TouchableOpacity>
          </View>
        </View>
      </ImageBackground>
    </TouchableWithoutFeedback>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 15,
  },
  headerText: {
    fontSize: 24,
    color: "white",
    textAlign: "center",
  },
  backButton: {
    position: "absolute",
    left: 15,
  },
  recipientContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 15,
    borderRadius: 10,
    marginBottom: 10,
  },
  recipientLabel: {
    color: "white",
    fontSize: 20,
    marginTop: -6,
  },
  picker: {
    flex: 1,
    height: 40,
    marginLeft: 10,
  },
  separator: {
    height: 1,
    backgroundColor: "white",
    marginHorizontal: 15,
    marginBottom: 10,
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  messageContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
    marginBottom: 10,
    position: "absolute",
    bottom: 0,
    width: "100%",
    paddingHorizontal: 15,
    paddingBottom: 20,
    marginLeft: -20,
  },
  textInput: {
    flex: 1,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 15,
    fontSize: 18,
    textAlignVertical: "top",
    marginHorizontal: 10,
  },
  sendButton: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
  },
  attachButton: {
    backgroundColor: "white",
    borderRadius: 50,
    padding: 10,
  },
});

export default CreateMessageScreen;
