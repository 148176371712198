import axios from "axios";
import { API_URL, WEBSOCKET_URL } from "./config";

const axiosAPIInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

export const createMessageRoom = async (jwt_token, user_id) => {
  console.log("entered createMessageRoom");
  try {
    const response = await axiosAPIInstance.put(
      "/createMessageRoom",
      {
        idUser: user_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt_token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error creating message room:", error);
    return null;
  }
};

export const sendMessage = async (
  jwt_token,
  idDirectConversation,
  message,
  socket
) => {
  const messagePayload = {
    routeKey: "MESSAGE",
    action: "MESSAGE",
    idDirectConversation: idDirectConversation,
    jwt_token: jwt_token,
    message: message,
    type: "send_message",
  };

  try {
    socket.send(JSON.stringify(messagePayload));
    console.log("Message sent:", messagePayload);
  } catch (error) {
    console.error("Error sending message:", error);
  }
};

export const sendRead = async (
  jwt_token,
  idDirectConversation,
  message_id,
  socket
) => {
  const readPayload = {
    routeKey: "READ",
    action: "READ",
    idDirectConversation: idDirectConversation,
    jwt_token: jwt_token,
    message_id: message_id,
    type: "read_message",
  };

  try {
    socket.send(JSON.stringify(readPayload));
    console.log("Read sent:", readPayload);
  } catch (error) {
    console.error("Error sending message:", error);
  }
};

export const getMessages = async (jwt_token, roomId) => {
  console.log("entered getMessages");
  try {
    const response = await axiosAPIInstance.post(
      `/roomMessages`,
      {
        roomId: roomId,
      },
      {
        headers: {
          Authorization: jwt_token,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching messages:", error);
    return [];
  }
};
