import React, { useCallback } from "react";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { getUserRole } from "../services/authService";
import StartScreen from "../views/Auth/StartScreen";

const withAuth = (WrappedComponent, isAuthRequired, allowedRoles = []) => {
  return (props) => {
    const navigation = useNavigation();

    useFocusEffect(
      useCallback(() => {
        const checkUser = async () => {
          const jwt_token = localStorage.getItem("jwt_token");
          if (!jwt_token) {
            if (isAuthRequired) {
              navigation.navigate("StartScreen");
            }
            return;
          }

          let role = localStorage.getItem("role");

          if (!role || role === "undefined" || role === "Undefined") {
            var data = await getUserRole(jwt_token);
            role = data.role;
            localStorage.setItem("role", role);
            localStorage.setItem("user_id", data.user_id);
          }

          // Redirect based on role
          if (WrappedComponent === StartScreen) {
            if (role === "Company") {
              navigation.navigate("CompanyLandingScreen");
            } else if (role === "Investor") {
              navigation.navigate("InvestorLandingScreen");
            }
          } else if (allowedRoles.length > 0 && !allowedRoles.includes(role)) {
            // If the user's role is not allowed, navigate to the appropriate landing page
            if (role === "Company") {
              navigation.navigate("CompanyLandingScreen");
            } else if (role === "Investor") {
              navigation.navigate("InvestorLandingScreen");
            } else {
              navigation.navigate("StartScreen");
            }
            return;
          }
        };

        checkUser();
      }, [navigation])
    );

    return <WrappedComponent {...props} />;
  };
};

export default withAuth;
