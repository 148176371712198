import React from 'react';
import useFonts from '../../hooks/useFonts'; // Adjust the path as necessary
import { View, Text, TouchableOpacity, Image, ImageBackground, StyleSheet } from 'react-native';

const StartScreen = ({ navigation }) => {
  const fontsLoaded = useFonts();

  if (!fontsLoaded) {
    return null; // Render nothing or a loading spinner
  }

  const logoWidth = 393;
  const logoHeight = 258;
  const companyNameSize = 50; // Renamed for clarity
  const buttonSize = 25;      // Button font size

  return (
    <ImageBackground source={require('../../assets/background/grey.jpg')} style={styles.backgroundImage}>
      <View style={styles.container}>
        <Image source={require('../../assets/logo/logo.png')} style={[styles.logo, { width: logoWidth, height: logoHeight }]} />
        <Text style={[styles.companyName, { fontSize: companyNameSize }]}>VELES SECURITIES</Text>

        <TouchableOpacity style={[styles.button, styles.loginButton]} onPress={() => navigation.navigate('LoginScreen')}>
          <Text style={[styles.buttonText, { fontSize: buttonSize }]}>LOGIN</Text>
        </TouchableOpacity>

        <TouchableOpacity style={[styles.button, styles.accountButton]} onPress={() => navigation.navigate('SignUpScreen')}>
          <Text style={[styles.buttonText, { fontSize: buttonSize }]}>CREATE AN ACCOUNT</Text>
        </TouchableOpacity>

        <TouchableOpacity style={styles.contactContainer} onPress={() => navigation.navigate('ContactScreen')}>
          <Text style={styles.contactText}>CONTACT US</Text>
        </TouchableOpacity>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    resizeMode: 'cover',
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  logo: {
    marginBottom: 30,
  },
  companyName: {
    fontFamily: 'zen',
    letterSpacing: 0.5,
    color: '#000000',
    textAlign: 'center',
    marginBottom: 20,
    // Removed fontSize from here
  },
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 366,
    height: 35,
    borderRadius: 0,
    textAlign: 'center',
    backgroundColor: 'rgba(217, 217, 217, 0.4)',
    marginBottom: 20,
  },
  buttonText: {
    fontFamily: 'genos',
    letterSpacing: 0.25,
    lineHeight: 35,
    color: '#000000',
    opacity: 0.5,
    // Removed fontSize from here
  },
  contactContainer: {
    position: 'absolute',
    bottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contactText: {
    fontFamily: 'genos',
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: '#000000',
    opacity: 0.5,
    textAlign: 'center',
  },
  loginButton: {
    // Additional styles specific to login button
  },
  accountButton: {
    // Additional styles specific to account button
  },
});

export default StartScreen;

