import React, { useState, useEffect } from "react";
import useFonts from "../../hooks/useFonts"; // Adjust the path as necessary
import { loginUser } from "../../services/authService"; // Adjust the path as necessary
import {
  KeyboardAvoidingView,
  Platform,
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
  StyleSheet,
  TextInput,
  Alert,
} from "react-native";
import { Ionicons } from "@expo/vector-icons"; // Ensure you have @expo/vector-icons installed

const LoginScreen = ({ navigation }) => {
  const fontsLoaded = useFonts();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  if (!fontsLoaded) {
    return null;
  }

  const handleLogin = async () => {
    try {
      localStorage.setItem("email", email);
      var response_data = await loginUser(email, password);

      console.log(response_data);

      if (response_data.message === "Not verified") {
        navigation.navigate("AccountVerificationScreen");
        return;
      }
      if (!response_data.role) {
        navigation.navigate("StartScreen");
        return;
      }
      var role = response_data.role;
      var jwt_token = response_data.jwt_token;

      localStorage.setItem("jwt_token", jwt_token);
      localStorage.setItem("role", role);

      switch (role) {
        case "Company":
          navigation.navigate("CompanyLandingScreen");
          break;
        case "Investor":
          navigation.navigate("InvestorLandingScreen");
          break;
        default:
          navigation.navigate("StartScreen");
          break;
      }
    } catch (error) {
      Alert.alert("Login Failed", "Invalid email or password");
    }
  };

  const logoWidth = 393;
  const logoHeight = 258;
  const companyNameSize = 50; // Renamed for clarity

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingView}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          enabled
        >
          {
            <TouchableOpacity
              style={styles.backButton}
              onPress={() => navigation.navigate("StartScreen")}
            >
              <Ionicons name="arrow-back" size={24} color="black" />
            </TouchableOpacity>
          }
          <View style={styles.inner}>
            <Image
              source={require("../../assets/logo/logo.png")}
              style={[styles.logo, { width: logoWidth, height: logoHeight }]}
            />
            <Text style={[styles.companyName, { fontSize: companyNameSize }]}>
              VELES SECURITIES
            </Text>

            <TextInput
              placeholder="EMAIL"
              value={email}
              onChangeText={setEmail}
              style={styles.inputField}
              placeholderTextColor="#000000"
            />

            <TextInput
              placeholder="PASSWORD"
              value={password}
              onChangeText={setPassword}
              secureTextEntry
              style={styles.inputField}
              placeholderTextColor="#000000"
            />

            <TouchableOpacity
              onPress={handleLogin}
              style={styles.contactContainer}
            >
              <Text style={styles.contactText}>LOGIN</Text>
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => navigation.navigate("ForgotPassword")}
              style={styles.contactContainer}
            >
              <Text style={styles.contactText}>FORGOT PASSWORD</Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
        <View style={styles.fullWidthContainer}>
          <TouchableOpacity
            onPress={() => navigation.navigate("Contact")}
            style={styles.contactContainer}
          >
            <Text style={styles.contactText}>CONTACT US</Text>
          </TouchableOpacity>
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  inner: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  logo: {
    marginBottom: 30,
  },
  companyName: {
    marginBottom: 20,
    fontFamily: "zen",
    letterSpacing: 0.5,
    color: "#000000",
    textAlign: "center",
  },
  backButton: {
    position: "absolute",
    top: 40,
    left: 20,
    zIndex: 1,
  },
  inputField: {
    width: 366,
    height: 35,
    borderRadius: 0,
    textAlign: "center",
    marginBottom: 15,
    backgroundColor: "rgba(217, 217, 217, 0.4)",
    fontFamily: "genos",
    fontSize: 25,
    color: "#000000",
    paddingHorizontal: 10,
  },
  contactContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  fullWidthContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  contactText: {
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
});

export default LoginScreen;
