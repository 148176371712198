import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  Modal,
  Linking,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  subscribe,
  unsubscribe,
  check_subscribed,
} from "../../services/userService";

const { height: screenHeight } = Dimensions.get("window");

const CompanySettingsScreen = ({ navigation }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const currentUrl = window.location.href;
  const regex = /^(https?:\/\/[^\/]+)/;
  const match = currentUrl.match(regex);

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      var jwt_token = localStorage.getItem("jwt_token");
      const subscribed = await check_subscribed(jwt_token);
      setIsSubscribed(subscribed);
    };
    checkSubscriptionStatus();
  }, []);

  const signOut = () => {
    localStorage.clear();
    navigation.navigate("StartScreen");
  };

  const handleSubscribe = async () => {
    setModalVisible(true);
    var jwt_token = localStorage.getItem("jwt_token");

    const baseUrl = match[1];
    console.log(baseUrl);

    try {
      var data = await subscribe(jwt_token, baseUrl);
      console.log(data);
      if (data && data.redirect_link) {
        Linking.openURL(data.redirect_link);
      } else {
        alert("Failed to create payment link. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    } finally {
      setModalVisible(false);
    }
  };

  const handleUnsubscribe = async () => {
    setIsLoading(true);
    var jwt_token = localStorage.getItem("jwt_token");

    try {
      const response = await unsubscribe(jwt_token);
      if (response.message) {
        alert("You have successfully unsubscribed.");
        setIsSubscribed(false);
      } else {
        alert("Failed to unsubscribe. Please try again.");
      }
    } catch (error) {
      alert("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <TouchableOpacity
          style={styles.header}
          onPress={() => navigation.navigate("CompanyLandingScreen")}
        >
          <FontAwesomeIcon
            icon={faArrowLeft}
            color="white"
            size={30}
            style={styles.headerIcon}
          />
        </TouchableOpacity>

        <TouchableOpacity
          style={styles.card}
          onPress={() => navigation.navigate("CompanyDocumentationScreen")}
        >
          <Text style={styles.settingsText}>COMPANY DOCUMENTATION</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.card}
          onPress={() => navigation.navigate("FAQScreen")}
        >
          <Text style={styles.settingsText}>FAQ</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.card}
          onPress={isSubscribed ? handleUnsubscribe : handleSubscribe}
        >
          <Text style={styles.settingsText}>
            {isSubscribed ? "UNSUBSCRIBE" : "SUBSCRIBE"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.card} onPress={signOut}>
          <Text style={styles.settingsText}>SIGN OUT</Text>
        </TouchableOpacity>
      </View>
      <TouchableOpacity
        style={styles.contactContainer}
        onPress={() => navigation.navigate("ContactScreen")}
      >
        <Text style={styles.contactText}>CONTACT US</Text>
      </TouchableOpacity>

      <Modal
        transparent={true}
        animationType="fade"
        visible={isModalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.modalContent}>
            <ActivityIndicator size="large" color="#0000ff" />
            <Text style={styles.modalText}>Creating payment link...</Text>
          </View>
        </View>
      </Modal>

      {isLoading && (
        <Modal
          transparent={true}
          animationType="fade"
          visible={isLoading}
          onRequestClose={() => setIsLoading(false)}
        >
          <View style={styles.modalOverlay}>
            <View style={styles.modalContent}>
              <ActivityIndicator size="large" color="#0000ff" />
              <Text style={styles.modalText}>Processing your request...</Text>
            </View>
          </View>
        </Modal>
      )}
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingHorizontal: 3,
    paddingBottom: 15,
  },
  headerIcon: {
    alignSelf: "center",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {
    height: screenHeight - 210,
  },
  card: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    marginVertical: 10,
    width: "100%",
    justifyContent: "space-between",
  },
  settingsText: {
    margin: "auto",
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
  contactContainer: {
    position: "absolute",
    bottom: 20,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  contactText: {
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
  },
  modalText: {
    marginTop: 15,
    fontSize: 18,
    textAlign: "center",
  },
});

export default CompanySettingsScreen;
