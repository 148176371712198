import axios from "axios";
import { API_URL } from "./config";

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

export const createInvestor = async (jwt_token) => {
  console.log(jwt_token);
  const response = await axiosInstance.put(
    "/createInvestor",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    }
  );
  return response.data;
};

export const createCompany = async (jwt_token) => {
  const response = await axiosInstance.put(
    "/createCompany",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    }
  );
  return response.data;
};

export const updateInvestorProfile = async (jwt_token, formData) => {
  const response = await axiosInstance.post(
    "/investorUpdateProfile",
    {
      formData: formData,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    }
  );
  return response.data;
};

export const getInvestorProfile = async (jwt_token) => {
  const response = await axiosInstance.get("/investorProfileSettings", {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt_token,
    },
  });
  console.log(response.data.profile_settings[0]);
  return response.data.profile_settings[0];
};

export const addCompanyBasics = (jwt_token, formData) => {
  try {
    const response = axiosInstance.post(
      "/companyBasicInformation",
      {
        formData: formData,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt_token,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return;
  }
};

export const updateInvestorPersonalInformation = async (
  jwt_token,
  formData
) => {
  const response = await axiosInstance.post(
    "/investorUpdatePersonalInformation",
    {
      formData: formData,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    }
  );
  return response.data;
};

export const getInvestorPersonalInformation = async (jwt_token) => {
  const response = await axiosInstance.get("/investorPersonalInformation", {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt_token,
    },
  });
  console.log(response.data.personal_information);
  return response.data.personal_information[0];
};

export const updateCompanyDocumentation = async (jwt_token, formData) => {
  const response = await axiosInstance.post(
    "/updateCompanyInformation",
    {
      formData: formData,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    }
  );
  return response.data;
};

export const getCompanyDocumentation = async (jwt_token) => {
  const response = await axiosInstance.get("/companyInformation", {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt_token,
    },
  });
  return response.data.company_information;
};

export const getFAQ = async (jwt_token) => {
  const response = await axiosInstance.get("/FAQ", {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt_token,
    },
  });
  console.log(response.data.faq);
  return response.data.faq;
};

export const getConversations = async (jwt_token) => {
  const response = await axiosInstance.get("/conversations", {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt_token,
    },
  });
  console.log(response.data);
  return response.data.conversations;
};

export const getRecipients = async (jwt_token) => {
  try {
    const response = await axiosInstance.get("/recipients", {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    });
    return response.data.recipients;
  } catch (error) {
    console.error("Error fetching recipients:", error);
    return [];
  }
};

export const subscribe = async (jwt_token, baseUrl) => {
  try {
    const response = await axiosInstance.post(
      "/subscribe",
      {
        baseUrl: baseUrl,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt_token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error subscribe:", error);
    return [];
  }
};

export const unsubscribe = async (jwt_token) => {
  try {
    const response = await axiosInstance.post(
      "/unsubscribe",
      {},
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt_token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error subscribe:", error);
    return [];
  }
};

export const validate_subscription = async (jwt_token, session_id) => {
  try {
    const response = await axiosInstance.post(
      "/validateSubscription",
      {
        session_id: session_id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt_token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error validate_subscription:", error);
    return [];
  }
};

export const check_subscribed = async (jwt_token) => {
  const response = await axiosInstance.get("/subscribed", {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt_token,
    },
  });
  return response.data.subscribed;
};

export const request_nda = async (jwt_token, idDeal) => {
  try {
    const response = await axiosInstance.post(
      "/userNDA",
      {
        idDeal: idDeal,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt_token,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error validate_subscription:", error);
    return [];
  }
};
