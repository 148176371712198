import axios from "axios";
import { API_URL } from "./config";

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

export const registerUser = async (email, password, userType) => {
  const response = await axiosInstance.post(
    "/userRegister",
    {
      email: email,
      password: password,
      user_type: userType,
    },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response.status;
};

export const loginUser = async (email, password) => {
  try {
    const response = await axiosInstance.post(
      "/userLogin",
      {
        email: email,
        password: password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error) {
    if (error.response) {
      console.log(error.response);
      return error.response.data;
    } else if (error.request) {
      console.log(error.request);
    } else {
      console.log("Error", error.message);
    }
    console.log(error.config);
  }
};

export const verifyEmailUser = async (email, verification_code) => {
  try {
    const response = await axiosInstance.post(
      "/userVerifyEmail",
      {
        email: email,
        verification_code: verification_code,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {}
};

export const ResendVerificationEmailUser = async (email) => {
  try {
    const response = await axiosInstance.post(
      "/userResendVerificationEmail",
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {}
};

export const getUserRole = async (jwt_token) => {
  try {
    const response = await axiosInstance.get(
      "/userRole",

      {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt_token,
        },
      }
    );

    return response.data;
  } catch (error) {}
};
