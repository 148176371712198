import { useState, useEffect } from 'react';
import * as Font from 'expo-font';

const useFonts = () => {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const loadFonts = async () => {
      try {
        await Font.loadAsync({
          'zen': require('../assets/fonts/zen.ttf'),
          'genos': require('../assets/fonts/genos.ttf'),
          // Add more fonts if necessary
        });
        setFontsLoaded(true);
      } catch (e) {
        console.log('Error loading fonts', e);
      }
    };

    loadFonts();
  }, []);

  return fontsLoaded;
};

export default useFonts;

