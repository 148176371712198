import React, { useEffect, useState } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
  StyleSheet,
  ScrollView,
  Dimensions,
  Linking,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faArrowLeft,
  faCaretDown,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";
import { getFAQ } from "../services/userService";

const { height: screenHeight } = Dimensions.get("window");

const FAQScreen = ({ navigation }) => {
  const [expandedIndices, setExpandedIndices] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const toggleExpand = (index) => {
    setExpandedIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const fetchFAQs = async () => {
    var jwt_token = localStorage.getItem("jwt_token");
    var data = await getFAQ(jwt_token);
    setFaqs(data);
    localStorage.setItem("faqs", JSON.stringify(data));
    localStorage.setItem("faqs_expiration", Date.now() + 60 * 60 * 1000);
  };

  useEffect(() => {
    const loadFAQs = () => {
      const storedFAQs = localStorage.getItem("faqs");
      const faqsExpiration = localStorage.getItem("faqs_expiration");

      if (storedFAQs && faqsExpiration && Date.now() < faqsExpiration) {
        setFaqs(JSON.parse(storedFAQs));
      } else {
        fetchFAQs();
      }
    };
    loadFAQs();
  }, []);

  return (
    <ImageBackground
      source={require("../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <TouchableOpacity
        style={styles.header}
        onPress={() => navigation.navigate("StartScreen")}
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          color="white"
          size={30}
          style={styles.headerIcon}
        />
      </TouchableOpacity>
      <View style={styles.container}>
        <ScrollView contentContainerStyle={styles.scrollViewContent}>
          {faqs.map((item, index) => (
            <View key={index} style={styles.item}>
              <TouchableOpacity
                style={styles.titleContainer}
                onPress={() => toggleExpand(index)}
              >
                <Text style={styles.title}>{item.Title}</Text>
                <FontAwesomeIcon
                  icon={
                    expandedIndices.includes(index) ? faCaretUp : faCaretDown
                  }
                  size={24}
                />
              </TouchableOpacity>
              {expandedIndices.includes(index) && (
                <View style={styles.content}>
                  <Text style={styles.text}>{item.Description}</Text>
                  <TouchableOpacity
                    onPress={() => Linking.openURL(item.YoutubeLink)}
                  >
                    <Image
                      style={styles.thumbnail}
                      source={{
                        uri: `https://img.youtube.com/vi/${
                          item.YoutubeLink.split("v=")[1]
                        }/0.jpg`,
                      }}
                    />
                  </TouchableOpacity>
                </View>
              )}
            </View>
          ))}
        </ScrollView>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    position: "absolute",
    marginBottom: 20,
  },
  headerIcon: {
    marginTop: 10,
  },
  container: {
    marginTop: 50,
    padding: 20,
    height: screenHeight - 50,
  },
  scrollViewContent: {
    paddingVertical: 20,
    paddingBottom: 40,
  },
  item: {
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    marginVertical: 10,
    padding: 10,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: 24,
    fontFamily: "genos",
  },
  content: {
    marginTop: 10,
  },
  text: {
    fontSize: 20,
    fontFamily: "genos",
    marginBottom: 10,
  },
  thumbnail: {
    width: "100%",
    height: 200,
    borderRadius: 10,
  },
});

export default FAQScreen;
