import React, { useState, useEffect, useCallback } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
  ScrollView,
  Dimensions,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import { faArrowLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getChatbotConversations } from "../../services/chatbotService";
import { useFocusEffect } from "@react-navigation/native";

const { height: screenHeight } = Dimensions.get("window");

const ChatbotPreviousConversationsScreen = ({ navigation }) => {
  const [conversations, setConversations] = useState([]);

  const fetchConversation = async () => {
    var jwt_token = localStorage.getItem("jwt_token");
    const data = await getChatbotConversations(jwt_token);
    console.log(data);
    setConversations(data.conversations);
  };

  useFocusEffect(
    useCallback(() => {
      fetchConversation();
    }, [])
  );

  const handleNavigation = (roomId, name) => {
    localStorage.setItem("selectedChatbotRoomId", roomId);
    localStorage.setItem("selectedChatbotTopicName", name);
    navigation.navigate("ChatbotConversationScreen");
  };

  const groupByDate = (conversations) => {
    const grouped = conversations.reduce((groups, conversation) => {
      const date = conversation.Date.split(" ")[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(conversation);
      return groups;
    }, {});
    return Object.keys(grouped)
      .sort((a, b) => new Date(b) - new Date(a))
      .map((date) => ({ date, conversations: grouped[date] }));
  };

  const renderConversations = (conversations) => {
    return conversations.map((item, index) => (
      <TouchableOpacity
        onPress={() => handleNavigation(item.idChatbotConversation, item.Topic)}
        key={index}
      >
        <View key={item.idChatbotConversation} style={styles.card}>
          <View style={styles.leftSection}>
            <Text style={styles.recipientName}>{item.Topic}</Text>
          </View>
        </View>
      </TouchableOpacity>
    ));
  };

  const renderGroupedConversations = (groupedConversations) => {
    return groupedConversations.map((group, index) => (
      <View key={index}>
        <View style={styles.dateDivider}>
          <Text style={styles.dateDividerText}>{group.date}</Text>
        </View>
        {renderConversations(group.conversations)}
      </View>
    ));
  };

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.navigate("StartScreen")}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              color="white"
              size={30}
              style={styles.headerIcon}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => navigation.navigate("ChatbotConversationScreen")}
          >
            <FontAwesomeIcon
              icon={faPlus}
              color="white"
              size={30}
              style={styles.headerIcon}
            />
          </TouchableOpacity>
        </View>

        <View style={styles.scrollContainer}>
          <ScrollView contentContainerStyle={styles.scrollViewContent}>
            {renderGroupedConversations(groupByDate(conversations))}
          </ScrollView>
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingHorizontal: 10,
    paddingBottom: 5,
  },
  headerIcon: {
    alignSelf: "center",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {
    height: screenHeight - 80,
  },
  scrollViewContent: {
    paddingVertical: 20,
  },
  card: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    padding: 15,
    marginBottom: 15,
    width: "100%",
    justifyContent: "space-between",
    borderRadius: 10,
  },
  leftSection: {
    flexDirection: "column",
    flex: 1,
  },
  recipientName: {
    fontSize: 18,
    fontWeight: "500",
    color: "#333",
  },
  lastMessageDate: {
    fontSize: 14,
    color: "#666",
    marginTop: 5,
  },
  dateDivider: {
    backgroundColor: "#333",
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 5,
    marginVertical: 10,
  },
  dateDividerText: {
    color: "white",
    textAlign: "center",
  },
});

export default ChatbotPreviousConversationsScreen;
