import axios from "axios";
import { API_URL } from "./config";

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

export const getDeals = async (jwt_token) => {
  const response = await axiosInstance.get("/viewDeals", {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt_token,
    },
  });
  console.log(response);
  return response.data;
};

export const getInvestors = async (jwt_token) => {
  const response = await axiosInstance.get("/viewInvestors", {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt_token,
    },
  });
  console.log(response);
  return response.data;
};

export const updateDealTerms = async (
  jwt_token,
  selectedChatbotRoomId,
  key,
  value
) => {
  console.log(jwt_token);
  const response = await axiosInstance.post(
    "/updateDealTerms",
    {
      idChatbotConversation: selectedChatbotRoomId,
      key: key,
      value: value,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    }
  );
  return response.data;
};

export const getDealTermsRecommendation = async (
  jwt_token,
  selectedChatbotRoomId
) => {
  console.log(jwt_token);
  const response = await axiosInstance.post(
    "/dealTermsRecommendation",
    {
      idChatbotConversation: selectedChatbotRoomId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    }
  );
  return response.data;
};

export const postDeal = async (jwt_token, selectedChatbotRoomId) => {
  console.log(jwt_token);
  const response = await axiosInstance.post(
    "/postDeal",
    {
      idChatbotConversation: selectedChatbotRoomId,
    },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    }
  );
  return response.data;
};

export const getCompanyDeals = async (jwt_token) => {
  const response = await axiosInstance.get("/viewMyDeals", {
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt_token,
    },
  });
  console.log(response);
  return response.data;
};
