import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
  StyleSheet,
  ScrollView,
  Dimensions,
  Modal,
  ActivityIndicator,
  TouchableWithoutFeedback,
} from "react-native";
import { FontAwesomeIcon } from "@fortawesome/react-native-fontawesome";
import {
  faSortAmountDownAlt,
  faBars,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { getDeals } from "../../services/dealsService";
import { request_nda } from "../../services/userService";
import Svg, { Path } from "react-native-svg";

const { height: screenHeight } = Dimensions.get("window");

const InvestorLandingScreen = ({ navigation }) => {
  const [deals, setDeals] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingModalVisible, setLoadingModalVisible] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState(null);

  const fetchDeals = async () => {
    try {
      const jwt_token = localStorage.getItem("jwt_token");
      if (jwt_token) {
        const fetchedDeals = await getDeals(jwt_token);
        setDeals(fetchedDeals.results);
        localStorage.setItem("deals", JSON.stringify(fetchedDeals.results));
        localStorage.setItem("deals_expiration", Date.now() + 5 * 60 * 1000);
      }
    } catch (error) {
      console.error("Error fetching deals:", error);
    }
  };

  useEffect(() => {
    const loadDeals = () => {
      const storedDeals = localStorage.getItem("deals");
      const dealsExpiration = localStorage.getItem("deals_expiration");

      if (storedDeals && dealsExpiration && Date.now() < dealsExpiration) {
        setDeals(JSON.parse(storedDeals));
      } else {
        fetchDeals();
      }
    };

    loadDeals();
    const intervalId = setInterval(() => {
      loadDeals();
    }, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  const extractPathData = (svgString) => {
    const pathRegex = /<path[^>]*d="([^"]*)"/;
    const match = svgString.match(pathRegex);
    return match ? match[1] : "";
  };

  const handleRequestNda = async (idDeal) => {
    setLoadingModalVisible(true);
    try {
      const jwt_token = localStorage.getItem("jwt_token");
      await request_nda(jwt_token, idDeal);
      setLoadingModalVisible(false);
      alert("NDA has been sent to your email.");
    } catch (error) {
      setLoadingModalVisible(false);
      console.error("Error requesting NDA:", error);
      alert("Failed to send NDA. Please try again later.");
    }
  };

  const renderInvestmentCard = (deal, index) => {
    if (deal.hidden && deal.hidden === 1) {
      return (
        <TouchableOpacity
          style={styles.blurredCard}
          key={index}
          onPress={() => handleRequestNda(deal.idDeal)}
        >
          <View style={styles.blurOverlay} />
        </TouchableOpacity>
      );
    }
    const pathData = extractPathData(deal.ProfilePhoto);
    return (
      <View style={styles.card} key={index}>
        <Svg
          width="50"
          height="50"
          viewBox={deal.Default ? "0 0 32 32" : "0 0 300 300"}
        >
          <Path d={pathData} />
        </Svg>
        <Text style={styles.symbol}>{deal.TickerSymbol}</Text>
        <Text style={styles.sectype}>{deal.SecurityType}</Text>
        <Text style={styles.amount}>
          ${deal.IssuanceAmount.toLocaleString()}M
        </Text>
      </View>
    );
  };

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <View style={styles.header}>
          <TouchableOpacity onPress={() => setModalVisible(true)}>
            <FontAwesomeIcon
              icon={faBars}
              color="white"
              size={30}
              style={styles.headerIcon}
            />
          </TouchableOpacity>
          <FontAwesomeIcon
            icon={faSortAmountDownAlt}
            color="white"
            size={30}
            style={styles.headerIcon}
          />
        </View>
        <View style={styles.scrollContainer}>
          <ScrollView contentContainerStyle={styles.scrollViewContent}>
            {deals.map((deal, index) => renderInvestmentCard(deal, index))}
          </ScrollView>
        </View>
        <View style={styles.footer}>
          <Text style={styles.footerText}>$100,000</Text>
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("ChatbotPreviousConversationsScreen")
            }
          >
            <Image
              source={require("../../assets/chat_icon/VELES.png")}
              style={styles.footerLogo}
            />
          </TouchableOpacity>

          <TouchableOpacity
            onPress={() => navigation.navigate("InvestorSettingsScreen")}
          >
            <Text style={styles.footerText}>SETTING</Text>
          </TouchableOpacity>
        </View>

        <Modal
          transparent={true}
          animationType="slide"
          visible={modalVisible}
          onRequestClose={() => setModalVisible(false)}
        >
          <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
            <View style={styles.modalOverlay}>
              <TouchableWithoutFeedback>
                <View style={styles.modalContent}>
                  <TouchableOpacity
                    style={styles.closeButton}
                    onPress={() => setModalVisible(false)}
                  >
                    <FontAwesomeIcon icon={faTimes} color="gray" size={24} />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={styles.modalButton}
                    onPress={() => {
                      setModalVisible(false);
                      navigation.navigate("InboxScreen");
                    }}
                  >
                    <Text style={styles.modalButtonText}>Inbox</Text>
                  </TouchableOpacity>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>

        <Modal
          transparent={true}
          animationType="fade"
          visible={loadingModalVisible}
          onRequestClose={() => setLoadingModalVisible(false)}
        >
          <View style={styles.loadingModalOverlay}>
            <View style={styles.loadingModalContent}>
              <ActivityIndicator size="large" color="#0000ff" />
              <Text style={styles.loadingModalText}>
                Sending an NDA to access this to your email...
              </Text>
            </View>
          </View>
        </Modal>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 10,
    paddingHorizontal: 3,
    paddingBottom: 15,
  },
  headerIcon: {
    alignSelf: "center",
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
    padding: 20,
  },
  scrollContainer: {
    height: screenHeight - 210,
  },
  card: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    padding: 15,
    marginVertical: 10,
    width: "100%",
    justifyContent: "space-between",
    borderRadius: 10,
  },
  blurredCard: {
    flex: 1,
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
  },
  blurOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(255, 255, 255, 0.5)",
    borderRadius: 10,
    height: 40,
  },
  symbol: {
    fontSize: 24,
    fontFamily: "genos",
  },
  sectype: {
    fontSize: 20,
    fontFamily: "genos",
  },
  amount: {
    fontSize: 24,
    fontFamily: "genos",
  },
  footer: {
    position: "sticky",
    bottom: 0,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 10,
    paddingHorizontal: 10,
  },
  footerText: {
    color: "white",
    fontSize: 24,
    fontFamily: "genos",
    paddingHorizontal: 8,
    paddingVertical: 12,
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  footerLogo: {
    width: 100,
    height: 100,
    resizeMode: "contain",
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  modalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    width: "80%",
    alignItems: "center",
  },
  closeButton: {
    alignSelf: "flex-end",
    marginBottom: 10,
  },
  modalButton: {
    marginVertical: 10,
    padding: 10,
    backgroundColor: "rgba(217, 217, 217, 0.75)",
    width: "100%",
    alignItems: "center",
  },
  modalButtonText: {
    color: "black",
    fontSize: 24,
    fontFamily: "genos",
  },
  loadingModalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  loadingModalContent: {
    backgroundColor: "white",
    padding: 20,
    borderRadius: 10,
    alignItems: "center",
  },
  loadingModalText: {
    marginTop: 15,
    fontSize: 18,
    fontFamily: "genos",
    textAlign: "center",
  },
});

export default InvestorLandingScreen;
