import React, { useState, useEffect } from "react";
import useFonts from "../../hooks/useFonts";
import { addCompanyBasics } from "../../services/userService";
import {
  KeyboardAvoidingView,
  Platform,
  View,
  Text,
  TouchableOpacity,
  Image,
  ImageBackground,
  StyleSheet,
  TextInput,
  Alert,
  Picker,
} from "react-native";

const CompanyAfterVerificationScreen = ({ navigation }) => {
  const fontsLoaded = useFonts();
  const [formData, setFormData] = useState({
    companyName: "",
    companyTickerSymbol: "",
    companyIndustry: "Healthcare",
    companyExchange: "NASDAQ",
  });

  if (!fontsLoaded) {
    return null; // Render nothing or a loading spinner
  }

  const handleInputChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: field === "companyTickerSymbol" ? value.toUpperCase() : value,
    });
  };

  const handleSumbit = async () => {
    try {
      var jwt_token = localStorage.getItem("jwt_token");
      const response = addCompanyBasics(jwt_token, JSON.stringify(formData));
      console.log(response);
      navigation.navigate("CompanyLandingScreen");
      return;
    } catch (error) {
      Alert.alert("Error");
    }
  };

  const logoWidth = 393;
  const logoHeight = 258;
  const companyNameSize = 50;

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingView}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          enabled
        >
          <View style={styles.inner}>
            <Image
              source={require("../../assets/logo/logo.png")}
              style={[styles.logo, { width: logoWidth, height: logoHeight }]}
            />
            <Text style={[styles.companyName, { fontSize: companyNameSize }]}>
              Company Details
            </Text>

            <TextInput
              placeholder="COMPANY NAME"
              value={formData.companyName}
              onChangeText={(value) => handleInputChange("companyName", value)}
              style={styles.inputField}
              placeholderTextColor="#000000"
            />

            <TextInput
              placeholder="COMPANY TICKER"
              value={formData.companyTickerSymbol}
              onChangeText={(value) =>
                handleInputChange("companyTickerSymbol", value)
              }
              style={styles.inputField}
              placeholderTextColor="#000000"
            />
            <View style={styles.pickerComponent}>
              <Text style={styles.pickerLabel}>Industry</Text>
              <Picker
                selectedValue={formData.companyIndustry}
                onValueChange={(value) =>
                  handleInputChange("companyIndustry", value)
                }
                style={[styles.picker, styles.inputField]}
              >
                {[
                  "Healthcare",
                  "Technology",
                  "Basic Materials",
                  "Financial Institutions",
                  "Industrial",
                  "Energy",
                  "Consumer/Retail",
                ].map((item) => (
                  <Picker.Item label={item} value={item} key={item} />
                ))}
              </Picker>
            </View>
            <View style={styles.pickerComponent}>
              <Text style={styles.pickerLabel}>Exchange</Text>
              <Picker
                selectedValue={formData.companyExchange}
                onValueChange={(value) =>
                  handleInputChange("companyExchange", value)
                }
                style={[styles.picker, styles.inputField]}
              >
                {["NASDAQ", "NYSE", "OTC"].map((item) => (
                  <Picker.Item label={item} value={item} key={item} />
                ))}
              </Picker>
            </View>
            <TouchableOpacity
              onPress={handleSumbit}
              style={styles.contactContainer}
            >
              <Text style={styles.contactText}>CONTINUE</Text>
            </TouchableOpacity>
          </View>
        </KeyboardAvoidingView>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  inner: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  logo: {
    marginBottom: 30,
  },
  companyName: {
    marginBottom: 20,
    fontFamily: "zen",
    letterSpacing: 0.5,
    color: "#000000",
    textAlign: "center",
  },
  inputField: {
    width: 366,
    height: 35,
    borderRadius: 0,
    textAlign: "center",
    marginBottom: 15,
    backgroundColor: "rgba(217, 217, 217, 0.4)",
    fontFamily: "genos",
    fontSize: 22,
    color: "#000000",
    paddingHorizontal: 10,
  },
  pickerLabel: {
    borderRadius: 0,
    textAlign: "center",
    fontFamily: "genos",
    fontSize: 25,
    color: "#000000",
  },
  picker: {
    width: 366,
    height: 35,
    marginBottom: 15,
    backgroundColor: "rgba(217, 217, 217, 0.4)",
  },
  pickerComponent: {
    marginTop: -10,
  },
  contactContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  contactText: {
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    backgroundColor: "rgba(217, 217, 217, 0.4)",
    textAlign: "center",
    width: 366,
    height: 35,
  },
});

export default CompanyAfterVerificationScreen;
