import axios from "axios";
import { API_URL } from "./config";

const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

const handleRequestError = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      console.error("Unauthorized: Invalid or expired token");
    } else if (error.response.status === 502) {
      console.error("Bad Gateway: Server is down or unreachable");
    } else {
      console.error(`Error: ${error.response.status}`);
    }
  } else {
    console.error("Network Error or Timeout");
  }
  throw error;
};

export const getChatbotConversations = async (jwt_token) => {
  try {
    const response = await axiosInstance.get("/chatbotConversations", {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    handleRequestError(error);
  }
};

export const createChatbotConversation = async (jwt_token) => {
  console.log(jwt_token);
  try {
    const response = await axiosInstance.put("/chatbotConversation", null, {
      headers: {
        "Content-Type": "application/json",
        Authorization: jwt_token,
      },
    });
    console.log(response);
    return response.data;
  } catch (error) {
    handleRequestError(error);
  }
};

export const getChatbotMessages = async (jwt_token, selectedChatbotRoomId) => {
  try {
    const response = await axiosInstance.post(
      "/chatbotMessages",
      {
        idChatbotConversation: selectedChatbotRoomId,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt_token,
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    handleRequestError(error);
  }
};

export const sendPromptMessage = async (
  jwt_token,
  selectedChatbotRoomId,
  question
) => {
  try {
    const response = await axiosInstance.post(
      "/prompt",
      {
        idChatbotConversation: selectedChatbotRoomId,
        prompt: question,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: jwt_token,
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    handleRequestError(error);
  }
};
