import React, { useState, useEffect } from "react";
import useFonts from "../../hooks/useFonts"; // Adjust the path as necessary
import { registerUser } from "../../services/authService"; // Adjust the path as necessary
import {
  KeyboardAvoidingView,
  Platform,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet,
  ImageBackground,
  Image,
  Alert,
  CheckBox,
  Modal,
  ScrollView,
} from "react-native";
import { Ionicons } from "@expo/vector-icons"; // Ensure you have @expo/vector-icons installed
import { tos_text } from "../../assets/tos";

const SignUpScreen = ({ navigation }) => {
  const fontsLoaded = useFonts();
  const [role, setRole] = useState(null); // 'company' or 'investor'
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("jwt_token")) {
      // navigation.navigate('ThankYou');
    }
  });

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };
  const handleBack = () => {
    if (!role) {
      navigation.navigate("StartScreen");
    } else {
      setRole(null);
    }
  };

  const handleSetRole = (_role) => {
    setRole(_role);
    localStorage.setItem("role", _role);
  };
  const handleSignUp = async () => {
    if (
      termsAccepted &&
      validateEmail(email) &&
      password !== "" &&
      password === confirmpassword
    ) {
      try {
        const status_code = await registerUser(email, password, role);
        if (status_code === 200) {
          localStorage.setItem("email", email);
          navigation.navigate("AccountVerificationScreen");
        }
      } catch (error) {
        Alert.alert(
          "Registration Failed",
          "Unable to register. Please try again."
        );
      }
    } else {
      Alert.alert(
        "Error",
        "Invalid credentials or terms not accepted. Please check your input."
      );
    }
  };

  if (!fontsLoaded) {
    return null;
  }

  const logoWidth = 393;
  const logoHeight = 258;
  const companyNameSize = 50; // Renamed for clarity

  return (
    <ImageBackground
      source={require("../../assets/background/grey.jpg")}
      style={styles.backgroundImage}
    >
      <View style={styles.container}>
        <KeyboardAvoidingView
          style={styles.keyboardAvoidingView}
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          keyboardVerticalOffset={Platform.OS === "ios" ? 160 : 0}
          enabled
        >
          {
            <TouchableOpacity
              style={styles.backButton}
              onPress={() => handleBack()}
            >
              <Ionicons name="arrow-back" size={24} color="black" />
            </TouchableOpacity>
          }

          <View style={styles.inner}>
            <Image
              source={require("../../assets/logo/logo.png")}
              style={[styles.logo, { width: logoWidth, height: logoHeight }]}
            />
            <Text style={[styles.companyName, { fontSize: companyNameSize }]}>
              VELES SECURITIES
            </Text>

            {!role && (
              <>
                <TouchableOpacity
                  style={styles.inputField}
                  onPress={() => handleSetRole("Company")}
                >
                  <Text style={styles.buttonText}>COMPANY</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.inputField}
                  onPress={() => handleSetRole("Investor")}
                >
                  <Text style={styles.buttonText}>INVESTOR</Text>
                </TouchableOpacity>
              </>
            )}

            {role === "Company" && (
              <>
                <TextInput
                  placeholder="COMPANY EMAIL"
                  value={email}
                  onChangeText={setEmail}
                  style={styles.inputField}
                  placeholderTextColor="#000000"
                />
                <TextInput
                  placeholder="PASSWORD"
                  value={password}
                  onChangeText={setPassword}
                  secureTextEntry
                  style={styles.inputField}
                  placeholderTextColor="#000000"
                />
                <TextInput
                  placeholder="CONFIRM PASSWORD"
                  value={confirmpassword}
                  onChangeText={setConfirmPassword}
                  secureTextEntry
                  style={styles.inputField}
                  placeholderTextColor="#000000"
                />
              </>
            )}

            {role === "Investor" && (
              <>
                <TextInput
                  placeholder="EMAIL"
                  value={email}
                  onChangeText={setEmail}
                  style={styles.inputField}
                  placeholderTextColor="#000000"
                />
                <TextInput
                  placeholder="PASSWORD"
                  value={password}
                  onChangeText={setPassword}
                  secureTextEntry
                  style={styles.inputField}
                  placeholderTextColor="#000000"
                />
                <TextInput
                  placeholder="CONFIRM PASSWORD"
                  value={confirmpassword}
                  onChangeText={setConfirmPassword}
                  secureTextEntry
                  style={styles.inputField}
                  placeholderTextColor="#000000"
                />
              </>
            )}

            {role && (
              <>
                <View style={styles.termsContainer}>
                  <CheckBox
                    value={termsAccepted}
                    onValueChange={setTermsAccepted}
                  />
                  <TouchableOpacity onPress={() => setModalVisible(true)}>
                    <Text style={styles.termsText}>
                      I accept terms of service
                    </Text>
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  onPress={handleSignUp}
                  style={styles.signUpButton}
                >
                  <Text style={styles.buttonText}>SIGN UP</Text>
                </TouchableOpacity>
              </>
            )}
          </View>
        </KeyboardAvoidingView>
        <View style={styles.fullWidthContainer}>
          <TouchableOpacity
            onPress={() => navigation.navigate("ContactScreen")}
            style={styles.contactContainer}
          >
            <Text style={styles.contactText}>CONTACT US</Text>
          </TouchableOpacity>
        </View>
      </View>

      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => setModalVisible(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <ScrollView contentContainerStyle={styles.modalScrollContent}>
              <Text style={styles.modalText}>{tos_text}</Text>
            </ScrollView>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setModalVisible(false)}
            >
              <Text style={styles.closeButtonText}>Close</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
  },
  container: {
    flex: 1,
  },
  keyboardAvoidingView: {
    flex: 1,
  },
  inner: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  },
  logo: {
    marginBottom: 30,
  },
  companyName: {
    marginBottom: 20,
    fontFamily: "zen",
    letterSpacing: 0.5,
    color: "#000000",
    textAlign: "center",
  },
  roleButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 366,
    height: 50,
    borderRadius: 0,
    textAlign: "center",
    backgroundColor: "rgba(217, 217, 217, 0.4)",
    marginBottom: 20,
  },
  backButton: {
    position: "absolute",
    top: 40,
    left: 20,
    zIndex: 1,
  },
  inputField: {
    width: 366,
    height: 35,
    borderRadius: 0,
    textAlign: "center",
    marginBottom: 15,
    backgroundColor: "rgba(217, 217, 217, 0.4)",
    fontFamily: "genos",
    fontSize: 25,
    color: "#000000",
    paddingHorizontal: 10,
  },
  termsContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  termsText: {
    marginLeft: 10,
    fontFamily: "genos",
    fontSize: 15,
    color: "#000000",
    opacity: 0.5,
  },
  buttonText: {
    textAlign: "center",
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
  },
  signUpButton: {
    justifyContent: "center",
    alignItems: "center",
    width: 366,
    height: 50,
    borderRadius: 0,
    textAlign: "center",
    backgroundColor: "rgba(217, 217, 217, 0.4)",
    marginBottom: 20,
  },
  contactContainer: {
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 20,
  },
  fullWidthContainer: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  contactText: {
    fontFamily: "genos",
    fontSize: 25,
    letterSpacing: 0.25,
    lineHeight: 35,
    color: "#000000",
    opacity: 0.5,
    textAlign: "center",
  },
  modalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
    padding: 20,
  },
  modalContent: {
    maxWidth: "100%",
    maxHeight: "100%",
    backgroundColor: "white",
    borderRadius: 10,
    padding: 20,
  },
  modalScrollContent: {
    paddingBottom: 20,
  },
  modalText: {
    fontSize: 16,
    marginBottom: 20,
  },
  closeButton: {
    alignItems: "center",
    paddingVertical: 10,
    backgroundColor: "#000",
    borderRadius: 5,
  },
  closeButtonText: {
    color: "white",
    fontSize: 18,
  },
});

export default SignUpScreen;
