export const tos_text = `
    TERMS OF SERVICE
    Effective Date: [Insert Date]
    Welcome to Veles Securities. These Terms of Service ("Terms") govern your use of the services provided by Veles Securities ("we," "us," or "our") through our website [www.velessecurities.com] and any other related services (collectively, the "Services"). By accessing or using our Services, you agree to be bound by these Terms.
    1. Acceptance of Terms By accessing or using our Services, you acknowledge that you have read, understood, and agree to be bound by these Terms, as well as our Privacy Policy, which is incorporated herein by reference. If you do not agree to these Terms, please do not use our Services.
    2. Eligibility To use our Services, you must be at least 18 years old or the legal age of majority in your jurisdiction, and you must have the legal capacity to enter into a binding contract. By using our Services, you represent and warrant that you meet these requirements.
    3. Registration and Account Security
    •	Account Registration: To access certain features of our Services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary to keep it accurate, current, and complete.
    •	Account Security: You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
    4. Use of Services
    •	Compliance with Laws: You agree to use our Services in compliance with all applicable laws, regulations, and rules, including but not limited to securities laws and anti-money laundering regulations.
    •	Prohibited Activities: You agree not to:
    o	Use our Services for any unlawful purpose or in any manner that could harm, disable, overburden, or impair our Services.
    o	Interfere with the security or integrity of our Services or any data stored therein.
    o	Attempt to gain unauthorized access to any portion of our Services or any other accounts, computer systems, or networks connected to our Services.
    5. Financial Transactions
    •	Trading and Investment Activities: Any trading or investment activities conducted through our Services are subject to the terms and conditions of the applicable agreements governing such activities, including but not limited to account agreements, margin agreements, and any other relevant documents.
    •	Risks: All investments involve risk, and past performance is not indicative of future results. You acknowledge that you are solely responsible for your investment decisions and that Veles Securities does not provide investment advice or recommendations.
    6. Fees and Charges
    •	Service Fees: You agree to pay all fees and charges associated with your use of our Services as described in our fee schedule, which is available on our website or provided to you separately. We reserve the right to change our fees at any time, and we will notify you of any such changes in accordance with applicable law.
    7. Intellectual Property
    •	Ownership: All content, trademarks, service marks, logos, and other intellectual property displayed on our website or provided through our Services are the property of Veles Securities or its licensors. You may not use, reproduce, or distribute any such content without our prior written consent.
    •	License: Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, revocable license to access and use our Services for your personal, non-commercial use.
    8. Disclaimers and Limitation of Liability
    •	Disclaimers: Our Services are provided on an "as is" and "as available" basis, without warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.
    •	Limitation of Liability: To the fullest extent permitted by law, Veles Securities and its affiliates, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of our Services, even if we have been advised of the possibility of such damages.
    9. Indemnification You agree to indemnify, defend, and hold harmless Veles Securities and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or related to your use of our Services or your violation of these Terms.
    10. Termination
    •	Termination by You: You may terminate your account and cease using our Services at any time by providing written notice to us.
    •	Termination by Us: We reserve the right to suspend or terminate your account or access to our Services at any time, with or without cause or notice, and without liability to you.
    11. Governing Law and Dispute Resolution
    •	Governing Law: These Terms shall be governed by and construed in accordance with the laws of the State of Illinois, without regard to its conflict of law principles.
    •	Dispute Resolution: Any disputes arising out of or related to these Terms or your use of our Services shall be resolved exclusively through binding arbitration in accordance with the rules of the American Arbitration Association (AAA). The arbitration shall take place in [Your City, State], and the arbitrator's decision shall be final and binding.
    12. Changes to These Terms We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting on our website, and your continued use of our Services after such changes have been posted will constitute your acceptance of the revised Terms.
    13. Contact Information If you have any questions about these Terms or our Services, please contact us at:
    Veles Securities
    744 W Gordon Ter, Chicago, IL, 60613
    admin@velessecurities.com
    5165280088
`;
